<div class="main-portfolio" [class.mobile]="project=='mobile'">
  <ng-container *ngIf="items else loading">
    <!--<div class="submenu" *ngIf="routing.isMain()">
      <mat-icon class="menu" matTooltip="Change View" [class.icon-view1]="portfolioView === 'mosaic'" [class.icon-view2]="portfolioView === 'list'" [matMenuTriggerFor]="menuView">more_vert</mat-icon>
      <mat-menu #menuView="matMenu">
        <button mat-menu-item (click)="changeView('list')">List</button>
        <button mat-menu-item (click)="changeView('mosaic')">Mosaic</button>
      </mat-menu>
    </div>-->
    <div class="submenu" *ngIf="routing.isMain()">
      <mat-icon class="menu icon-view2" matTooltip="Manage Portfolios" [matMenuTriggerFor]="menuPortfolioManagement">more_vert</mat-icon>
      <mat-menu #menuPortfolioManagement="matMenu">
        <button mat-menu-item *ngIf="auth.folio && !auth.folio.is_aggregated && !(auth.folio.sharing_mode === 'read')" [matMenuTriggerFor]="menuImportPortfolio">Import</button>
        <button mat-menu-item [matMenuTriggerFor]="menuExportPortfolio" *ngIf="routing.isDev() || auth.featureFlags.exportPortfolios">Export</button>
        <button mat-menu-item *ngIf="auth.folio && !auth.folio.sharing_mode" (click)="iShare()">Share</button>
      </mat-menu>
      <mat-menu #menuImportPortfolio="matMenu">
        <button mat-menu-item (click)="iImport(null, 'isinsAdd')">Entities from ISINs list</button>
        <button mat-menu-item *ngIf="auth.featureFlags.importPortfolios" (click)="fileInputPortfolio.click()">Entities (from file)</button>
        <input #fileInputPortfolio class="inptfile" type="file" accept=".json" value="" (click)="fileInputPortfolio.value = null" (change)="iImport($event, 'portfolio')" multiple/>
      </mat-menu>
      <mat-menu #menuExportPortfolio="matMenu">
        <!--<button mat-menu-item (click)="iExport('events')" *ngIf="routing.isDev()">Events (xlsx)</button>-->
        <button mat-menu-item (click)="iExport('portfolio')" *ngIf="auth.featureFlags.exportPortfolios">Portfolio (json)</button>
        <button mat-menu-item (click)="iExport('stats')" *ngIf="auth.featureFlags.exportPortfolios">Statistics (xlsx)</button>
      </mat-menu>
    </div>

    <div class="folio-entity" [class.empty]="items.length==0" *ngIf="portfolioView === 'list'">

      <div class="nodata" *ngIf="items.length==0 && auth.folio &&  !auth.folio.is_aggregated && !(auth.folio.sharing_mode === 'read') && auth.featureFlags.addEntities">
        <button mat-stroked-button (click)="newIndex()">Add Company</button>
      </div>

      <div class="table-main" *ngIf="items.length!=0">
        <div class="titolo">
          <div class="box-table">
            <div class="box-table-cont">
              <div class="tito">
                <span class="name" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">
                  <span class="sort" (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Entity </span>
                  <span [class.no-sort]="!(params.sort==='abc'||params.sort==='-abc')">
                    {{((params.sort==='abc') ? -1 : 1 )|trend}}
                  </span>
                </span>
              </div>
              <div class="info">
                <div class="cont">
                  <span class="score" *ngIf="!(auth.scorelabSettings.defaultScore === 'sentiment')" [class.sort-active]="params.sort==='score'||params.sort==='-score'">
                    <span class="sort" (click)="goSort('score')" [matTooltip]="scoreTooltip()" matTooltipPosition="above">{{auth.getScoreName().short}} </span>
                    <span [class.no-sort]="!(params.sort==='score'||params.sort==='-score')">
                      {{((params.sort==='score') ? 1 : -1 )|trend}}
                    </span>
                  </span>
                  <span class="percent" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'">
                    <span class="sort" (click)="goSort('senti')" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">Sent </span>
                    <span [class.no-sort]="!(params.sort==='senti'||params.sort==='-senti')">
                      {{((params.sort==='senti') ? 1 : -1 )|trend}}
                    </span>
                  </span>
                  <span class="numb" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
                    <span class="sort" (click)="goSort('trend')" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">ΔSent </span>
                    <span [class.no-sort]="!(params.sort==='trend'||params.sort==='-trend')">
                      {{((params.sort==='trend') ? 1 : -1 )|trend}}
                    </span>
                  </span>
                  <span class="count" [class.sort-active]="params.sort==='count'||params.sort==='-count'">
                    <span class="sort" (click)="goSort('count')" matTooltip="Total number of news articles" matTooltipPosition="above">Vol </span>
                    <span [class.no-sort]="!(params.sort==='count'||params.sort==='-count')">
                      {{((params.sort==='count') ? 1 : -1 )|trend}}
                    </span>
                  </span>
                  <span class="ratio" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'">
                    <span class="sort" (click)="goSort('ratio')" matTooltip="The relative change in the total number of news articles from the previous period" matTooltipPosition="above">ΔVol </span>
                    <span [class.no-sort]="!(params.sort==='ratio'||params.sort==='-ratio')">
                      {{((params.sort==='ratio') ? 1 : -1 )|trend}}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-cont">
          <div class="box-table" [class.active1]="(params.tag == ('m:' + item.entity.type + ':' + item.entity.alpha_id)) && (params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti' || params.sort === 'score' || params.sort === '-score')" [class.active2]="(params.tag == ('m:' + item.entity.type + ':' + item.entity.alpha_id)) && !(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti' || params.sort === 'score' || params.sort === '-score')" [class.no-color]="!(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti' || params.sort === 'score' || params.sort === '-score')" *ngFor="let item of items | orderBy: myOrder; let index = index;" [style.background]="(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti' || params.sort === 'score' || params.sort === '-score') ? getBackground(item) : ''">
            <div class="edit" *ngIf="auth.folio && !(auth.folio.sharing_mode === 'read') && !(auth.folio.is_aggregated) && auth.featureFlags.editEntities" (click)="editIndex(item.entity)">
              <div class="edit-border" [style.background]="!(params.sort === 'ratio' || params.sort === '-ratio' || params.sort === 'senti' || params.sort === '-senti' || params.sort === 'score' || params.sort === '-score') ? getBackground(item) : ''"></div>
              <div class="edit-icon">
                <mat-icon>mode_edit</mat-icon>
              </div>
            </div>
            <div class="box-table-cont" (click)="addTag(item)">
              <div class="tito" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'" [class.highlight-entity]="currentEntity === item.entity.alpha_id" (mouseenter)="onMouseEnterName(item.entity.alpha_id)" (mouseleave)="onMouseLeaveName()" (click)="$event.stopPropagation(); iNavigate(item);">{{item.entity.name}}</div>
              <div class="info">
                <div class="cont">
                  <span class="score" *ngIf="!(auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)" [class.sort-active]="params.sort==='score'||params.sort==='-score'" [class.sort]="params.sort==='score'||params.sort==='-score'" [matTooltip]="scoreTooltip()" matTooltipPosition="above">
                    <span *ngIf="item.score || (item.score === 0)">{{item.score| round:1}}</span>
                    <div class="loading-item" *ngIf="!item.score && !(item.score === null) && !(item.score === 0)">
                      <mat-spinner [diameter]="18"></mat-spinner>
                    </div>
                  </span>
                  <span class="score" *ngIf="!(auth.scorelabSettings.defaultScore === 'sentiment') && ['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)" [class.sort-active]="params.sort==='score'||params.sort==='-score'" [class.sort]="params.sort==='score'||params.sort==='-score'" [matTooltip]="scoreTooltip()" matTooltipPosition="above">
                    <span class="icon">
                      <img *ngIf="item.trend_type === 'bear'" src="assets/icon/bear.svg">
                      <img *ngIf="item.trend_type === 'bull'" src="assets/icon/bull.svg">
                    </span>
                    <span *ngIf="item.score || (item.score === 0)">{{(item.score).toFixed(1)}}</span>
                    <div class="loading-item" *ngIf="!item.score && !(item.score === null) && !(item.score === 0)">
                      <mat-spinner [diameter]="18"></mat-spinner>
                    </div>
                  </span>
                  <span class="percent" [class.sort-active]="params.sort==='senti'||params.sort==='-senti'" [class.sort]="params.sort==='senti'||params.sort==='-senti'" matTooltip="Sentiment Rating where 0% is very negative and 100% is very positive" matTooltipPosition="above">
                    <span>{{item.sentiment|percent}}</span>
                  </span>
                  <span class="numb" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'" [class.sort]="params.sort==='trend'||params.sort==='-trend'" matTooltip="Change in the sentiment for the period" matTooltipPosition="above">
                    <span>{{item.sentiment_delta*100|round|trend}}</span>
                    <span>{{item.sentiment_delta*100|round|negative}}</span>
                  </span>
                  <span class="count" [class.sort-active]="params.sort==='count'||params.sort==='-count'" [class.sort]="params.sort==='count'||params.sort==='-count'" matTooltip="Total number of news articles" matTooltipPosition="above">
                    <span>{{item.count}}</span>
                  </span>
                  <span class="ratio" [class.sort-active]="params.sort==='ratio'||params.sort==='-ratio'" [class.sort]="params.sort==='ratio'||params.sort==='-ratio'" matTooltip="The relative change in the total number of news articles from the previous period" matTooltipPosition="above">
                    <span class="pero">&#215;</span>
                    <span>{{item.volume_ratio | round:2}}</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <div class="folio-editin">
    <!--<button mat-raised-button class="goindex" [matMenuTriggerFor]="menuImport">
      <mat-icon>cloud_download</mat-icon>
      <span>IMPORT</span>
      <mat-menu #menuImport="matMenu">
        <button mat-menu-item (click)="iImport(null, 'isin')">ISINs list</button>
        <button mat-menu-item *ngIf="auth.featureFlags.importPortfolios" (click)="fileInputCollection.click()">Portfolios (from files)</button>
        <input #fileInputCollection class="inptfile" type="file" accept=".json" value="" (click)="fileInputCollection.value = null" (change)="iImport($event, 'portfolio')" multiple/>
      </mat-menu>
    </button>-->
    <button mat-raised-button class="goindex" *ngIf="auth.folio && !(auth.folio.sharing_mode === 'read') && auth.featureFlags.addEntities" (click)="newIndex()" matTooltip="{{(auth.folio && auth.folio.is_aggregated) ? 'Add / Remove Portfolios' : 'Add Company'}}">
      <mat-icon>add</mat-icon>
      <!--<span>ADD</span>-->
    </button>
    <!--<button mat-raised-button class="goindex" (click)="editFolio()">
      <mat-icon>border_color</mat-icon>
      <span>EDIT</span>
    </button>-->
    <!--<button mat-raised-button class="goindex" (click)="newFolio()">
      <mat-icon>create_new_folder</mat-icon>
      <span>NEW</span>
    </button>-->
    <!--<button mat-raised-button class="goindex" [matMenuTriggerFor]="menuExport" *ngIf="routing.isDev() || auth.featureFlags.exportPortfolios">
      <mat-icon>save</mat-icon>
      <span>EXPORT</span>
      <mat-menu #menuExport="matMenu">
        <button mat-menu-item (click)="iExport('events')" *ngIf="routing.isDev()">Events</button>
        <button mat-menu-item (click)="iExport('portfolio')" *ngIf="auth.featureFlags.exportPortfolios">Portfolio</button>
      </mat-menu>
    </button>-->
  </div>
  <ng-template #loading>
    <div class="loading">
      <mat-spinner [diameter]="50"></mat-spinner>
    </div>
  </ng-template>
</div>
