<div class="scanner-insights-main">

    <div class="scanner-insights-lhs">

        <div class="scanner-mosaic-header">
            {{(((auth.scorelabSettings.defaultScore === 'credit_risk') && !isCompanyTag && !isPneTag) || (['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore))) ? 'Sentiment Events' : auth.getScoreName().name + ' Events'}}
        </div>

        <div class="scanner-mosaic-contents">

            <div class="company-block" *ngIf="(auth.scorelabSettings.defaultScore === 'sentiment') || (['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)) || ((auth.scorelabSettings.defaultScore === 'credit_risk') && !isCompanyTag && !isPneTag)">
                <div class="company-menu">
                    <!--<div class="menu-left">Events</div>-->
                    <div class="treemap-item">
                        <div class="item-bar">
                            <div class="item-bar-cont">
                                <div class="bar-header">
                                    <div class="bar-name">Sentiment</div>
                                    <div class="bar-number">
                                        <span class="bar-count">{{mosaicScore ? (mosaicScore + '%') : ''}}</span>
                                    </div>
                                </div>
                                <div class="bar-cont">
                                    <mat-progress-bar class="bar-main" [class.score-pos-light]="routing.theme === 'light'" [class.score-pos-dark]="routing.theme !== 'light'" mode="determinate" [value]="(100 - mosaicScore)"></mat-progress-bar>
                                </div>
                                <div class="table-header">
                                    <div>Events</div>
                                    <div>No. Articles</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="company-treemap" *ngIf="iready else loadingEvents">
                    <ng-container *ngFor="let item of events | orderBy:'-count' | slice:0:5">
                        <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngIf="item.count > 0" (click)="iEvent(item)">
                            <div class="item-bar" [class.active]="qparam.eventid==item.event.id">
                                <div class="item-bar-cont">
                                    <div class="bar-header">
                                        <div class="bar-name">{{item.event.name}}</div>
                                        <div class="bar-number">
                                            <span class="bar-count">{{item.count}}</span>
                                        </div>
                                    </div>
                                    <div class="bar-cont">
                                        <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaro(events,item.count)"></mat-progress-bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="events==0" class="noevent">NO EVENTS</div>
                </div>
                <ng-template #loadingEvents>
                    <div class="loading" *ngIf="loading">
                        <mat-spinner [diameter]="50"></mat-spinner>
                    </div>
                    <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
                    <div class="nodata" *ngIf="!nodata && !loading">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
                </ng-template>
            </div>

            <div class="company-block" *ngIf="!(auth.scorelabSettings.defaultScore === 'sentiment') && !['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore) && !((auth.scorelabSettings.defaultScore === 'credit_risk') && !isCompanyTag && !isPneTag)">
                <div class="company-menu">
                    <div class="treemap-item">
                        <div class="item-bar">
                            <div class="item-bar-cont">
                                <div class="bar-header">
                                    <div class="bar-name">{{auth.getScoreName().name + ' Score'}}</div>
                                    <div class="bar-number">
                                        <span class="bar-count">{{mosaicScore ? (mosaicScore + ' / 10') : ''}}</span>
                                    </div>
                                </div>
                                <div class="bar-cont">
                                    <mat-progress-bar class="bar-main" [class.score-pos-light]="(auth.getScoreType() === 'pos') && (routing.theme === 'light')" [class.score-pos-dark]="(auth.getScoreType() === 'pos') && (routing.theme !== 'light')" [class.score-neg]="(auth.getScoreType() === 'neg')" mode="determinate" [value]="100 - (mosaicScore * 10).toFixed()"></mat-progress-bar>
                                </div>
                                <div class="table-header">
                                    <div>Events</div>
                                    <div>No. Articles</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="company-treemap" *ngIf="iready else loadingScoreEvents">
                    <ng-container *ngFor="let item of scoreEvents | orderBy:'-contrib_amount' | slice:0:5">
                        <div class="treemap-item" [class.mobile]="routing.isMobile()" *ngIf="item.contrib_amount > 0" (click)="iScoreEvent(item)">
                            <div class="item-bar" [class.active]="qparam.eventid==item.id">
                                <div class="item-bar-cont">
                                    <div class="bar-header">
                                        <div class="bar-name">{{item.name}}</div>
                                        <div class="bar-number">
                                            <span class="bar-count">{{item.contrib_amount}}</span>
                                        </div>
                                    </div>
                                    <div class="bar-cont">
                                        <mat-progress-bar class="bar-main" mode="determinate" [value]="iBaroScore(scoreEvents,item.contrib_amount)"></mat-progress-bar>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <div *ngIf="scoreEvents==0" class="noevent">NO EVENTS</div>
                </div>
                <ng-template #loadingScoreEvents>
                    <div class="loading" *ngIf="loading">
                        <mat-spinner [diameter]="50"></mat-spinner>
                    </div>
                    <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
                    <div class="nodata" *ngIf="!nodata && !loading">NO RESULTS FOUND, CHECK APPLIED FILTERS</div>
                </ng-template>
            </div>

        </div>

    </div>

    <div class="scanner-insights-rhs">

        <div class="scanner-chart-header">
            {{((['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore) && !isCompanyTag) || ((auth.scorelabSettings.defaultScore === 'credit_risk') && !isCompanyTag && !isPneTag)) ? 'Sentiment Trend' : auth.getScoreName().name + ' Trend'}}
        </div>

        <div class="scanner-chart-contents">
            <app-news-chart></app-news-chart>
        </div>

    </div>

</div>
