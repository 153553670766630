import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ReportViewComponent } from '../report-view/report-view.component';
import { ReportExportComponent } from '../report-export/report-export.component';
import { AuthService } from '../../../service/auth.service';
import { SelectionModel } from '@angular/cdk/collections';
import * as XLSX from 'xlsx';
import { forkJoin } from 'rxjs';

/**
 * This component display the list of the report's result.
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  query: any;
  type: any;
  queryForm: any;
  pageSize = 25;
  pageSizeOptions = [10, 25, 50, 100, 200, 500];
  newsLast = [];
  pageIndex = 0;
  previousPageIndex = 0;
  export: any;
  params: any;
  totalcount: number;
  currentSize: number;
  deload: boolean;
  labelLoading: boolean;
  displayedColumns: string[] = ['select', 'project', 'id', 'email', 'time', 'report', 'match', 'label'];
  selection = new SelectionModel<any>(true, []);
  dataSource: any;
  fullDataSource: any;

  constructor(
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public auth: AuthService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      this.inQuery();
    });
  }

  ngOnInit() {
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  inQuery() {
    this.selection.clear();
    const data = {
      page: this.pageIndex,
      items_per_page: this.pageSize
    };
    this.dataSource = [];
    this.totalcount = 0;
    const filters = [];
    if (this.params.query) {
      this.query = this.params.query.split(':');
      const field = this.query[0];
      let operator: any = '==';
      if (this.query[1] === 'less') { operator = '<'; }
      if (this.query[1] === 'more') { operator = '>'; }
      const value = this.query[2];
      filters.push({
        field,
        operator,
        value
      });
    }
    if (this.params.type) {
      this.type = this.params.type.split(':');
      const field = this.type[0];
      let operator: any = '==';
      if (this.type[1] === 'less') { operator = '<'; }
      if (this.type[1] === 'more') { operator = '>'; }
      const value = this.type[2];
      filters.push({
        field,
        operator,
        value
      });
    }
    if (filters.length > 0) {
      data['filters'] = filters;
    }
    let reportId = null;
    let reportType = null;
    filters.forEach(filter => {
      if (filter.field === 'id') {
        reportId = filter.value;
      } else if (filter.field === 'reportInfo.type') {
        reportType = filter.value;
      }
    });
    const size = (this.pageIndex + 1) * this.pageSize;
    this.currentSize = size;
    if (reportId) {
      this.auth.findReportById(reportId).subscribe(result => {
        this.dataSource = result.hits;
        this.fullDataSource = result.hits;
        this.totalcount = result.total_count;
      });
    } else {
      this.auth.findReports(reportType, size).subscribe(result => {
        this.fullDataSource = result.hits;
        this.dataSource = result.hits.slice((this.pageIndex * this.pageSize), (this.pageIndex + 1) * this.pageSize);
        this.totalcount = result.total_count;
      });
    }
  }

  goPage(event) {
    this.previousPageIndex = event.previousPageIndex;
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    if ((((this.pageIndex + 1) * this.pageSize) > this.currentSize) && (this.totalcount > this.currentSize)) {
      this.inQuery();
    } else {
      this.dataSource = this.fullDataSource.slice((this.pageIndex * this.pageSize), (this.pageIndex + 1) * this.pageSize);
    }
  }

  goDelete(id, index) {
    if (window.confirm('Do you want delete this report?')) {
      this.auth.removeMultipleReports([id]).subscribe(result => {
        this.snackBar.open('REPORT REMOVED', 'OK', { duration: 5000 });
        this.inQuery();
      });
    }
  }

  inReport(report) {
    const dialogRef = this.dialog.open(ReportViewComponent, {
      autoFocus: false,
      data: report
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.remove) {
          this.goDelete(result.remove, undefined);
        }
      }
    });
  }

  inExport() {
    this.dialog.open(ReportExportComponent, { data: this.selection.selected });
  }

  inFileExport() {
    const date = new Date();
    const displayDate = date.getFullYear() + '-' + date.getMonth() + '-' + date.getDate() + ' ' + date.getHours() + '-' + date.getMinutes() + '-' + date.getSeconds();

    let doubleArrayData;

    if (this.params.type && (this.params.type.split(':')[2] === 'event')) {
      doubleArrayData = [['Project', 'Id', 'Email', 'Time', 'Report', 'Subject', 'Label', 'Language', 'Sentence', 'Message', 'Event name', 'Event type', 'Event id', 'Participant name', 'Participant type', 'Participant id', 'News URL', 'Cockpit URL']];
    } else if (this.params.type && (this.params.type.split(':')[2] === 'entity')) {
      doubleArrayData = [['Project', 'Id', 'Email', 'Time', 'Report', 'Subject', 'Label', 'Language', 'Sentence', 'Message', 'Entity name', 'Entity type', 'Entity id', 'News URL', 'Cockpit URL']];
    } else {
      doubleArrayData = [['Project', 'Id', 'Email', 'Time', 'Report', 'Subject', 'Label', 'Language', 'Sentence', 'Message', 'Sentiment', 'Entity name', 'Entity type', 'Entity id', 'Event name', 'Event type', 'Event id', 'Participant name', 'Participant type', 'Participant id', 'News URL', 'Cockpit URL']];
    }

    this.selection.selected.forEach(report => {
      if (this.params.type && (this.params.type.split(':')[2] === 'event')) {
        doubleArrayData.push([
          (report.project) ? report.project : '',
          (report.id) ? report.id : '',
          (report.email) ? report.email : '',
          (report.time) ? new Date(report.time) : '',
          (report.report) ? report.report : '',
          (report.matching) ? report.matching : '',
          (report.label) ? report.label : '',
          (report.language) ? report.language : '',
          (report.reportInfo && report.reportInfo.sentence) ? report.reportInfo.sentence : '',
          (report.message) ? report.message : '',
          (report.reportInfo && report.reportInfo.event) ? report.reportInfo.event : '',
          (report.reportInfo && report.reportInfo.eventType) ? report.reportInfo.eventType : '',
          (report.reportInfo && report.reportInfo.eventId) ? report.reportInfo.eventId : '',
          (report.reportInfo && report.reportInfo.participant) ? report.reportInfo.participant : '',
          (report.reportInfo && report.reportInfo.participantType) ? report.reportInfo.participantType : '',
          (report.reportInfo && report.reportInfo.participantId) ? report.reportInfo.participantId : '',
          (report.url) ? report.url : '',
          (report.reportInfo && report.reportInfo.cockpitUrl) ? report.reportInfo.cockpitUrl : ''
        ]);
      } else if (this.params.type && (this.params.type.split(':')[2] === 'entity')) {
        doubleArrayData.push([
          (report.project) ? report.project : '',
          (report.id) ? report.id : '',
          (report.email) ? report.email : '',
          (report.time) ? new Date(report.time) : '',
          (report.report) ? report.report : '',
          (report.matching) ? report.matching : '',
          (report.label) ? report.label : '',
          (report.language) ? report.language : '',
          (report.reportInfo && report.reportInfo.sentence) ? report.reportInfo.sentence : '',
          (report.message) ? report.message : '',
          (report.reportInfo && report.reportInfo.entity) ? report.reportInfo.entity : '',
          (report.reportInfo && report.reportInfo.entityType) ? report.reportInfo.entityType : '',
          (report.reportInfo && report.reportInfo.entityId) ? report.reportInfo.entityId : '',
          (report.url) ? report.url : '',
          (report.reportInfo && report.reportInfo.cockpitUrl) ? report.reportInfo.cockpitUrl : ''
        ]);
      } else {
        doubleArrayData.push([
          (report.project) ? report.project : '',
          (report.id) ? report.id : '',
          (report.email) ? report.email : '',
          (report.time) ? new Date(report.time) : '',
          (report.report) ? report.report : '',
          (report.matching) ? report.matching : '',
          (report.label) ? report.label : '',
          (report.language) ? report.language : '',
          (report.reportInfo && report.reportInfo.sentence) ? report.reportInfo.sentence : '',
          (report.message) ? report.message : '',
          (report.reportInfo && report.reportInfo.sentiment) ? report.reportInfo.sentiment : '',
          (report.reportInfo && report.reportInfo.entity) ? report.reportInfo.entity : '',
          (report.reportInfo && report.reportInfo.entityType) ? report.reportInfo.entityType : '',
          (report.reportInfo && report.reportInfo.entityId) ? report.reportInfo.entityId : '',
          (report.reportInfo && report.reportInfo.event) ? report.reportInfo.event : '',
          (report.reportInfo && report.reportInfo.eventType) ? report.reportInfo.eventType : '',
          (report.reportInfo && report.reportInfo.eventId) ? report.reportInfo.eventId : '',
          (report.reportInfo && report.reportInfo.participant) ? report.reportInfo.participant : '',
          (report.reportInfo && report.reportInfo.participantType) ? report.reportInfo.participantType : '',
          (report.reportInfo && report.reportInfo.participantId) ? report.reportInfo.participantId : '',
          (report.url) ? report.url : '',
          (report.reportInfo && report.reportInfo.cockpitUrl) ? report.reportInfo.cockpitUrl : ''
        ]);
      }
    });

    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(doubleArrayData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, 'Report from ' + displayDate + '.xlsx');
  }

  iDelete() {
    if (window.confirm('Do you want delete all selected reports?')) {
      this.deload = true;
      const ids = this.selection.selected.map(report => report.id);
      this.auth.removeMultipleReports(ids).subscribe(() => {
        this.deload = false;
        this.snackBar.open('Selected reports have been removed', 'OK', { duration: 5000 });
        this.inQuery();
      });
    }
  }

  labelAllDone() {
    if (this.selection.selected.length !== 0) {
      if (window.confirm('Do you want to label all selected reports as \'done\'?')) {
        this.labelLoading = true;
        const reportsList = [];
        this.selection.selected.forEach(report => {
          reportsList.push({
            id: report.id,
            report_type: report.reportInfo.type,
            time: report.time,
            label: 'done'
          });
        });
        this.auth.changeReportLabel({
          commands: reportsList
        }).subscribe(() => {
          this.labelLoading = false;
          this.snackBar.open('Selected reports have been labeled as \'done\'', 'OK', { duration: 5000 });
          this.inQuery();
        });
      }
    }
  }

}
