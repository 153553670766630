<div class="chat-main">

   

    <!-- <div class="chat__history">
         <div class="chat__title">
        <span>{{headerText}}</span>
        <hr style="width:12%;text-align:left;margin-left:0">
    </div>
    <button mat-button="" class="mat-focus-indicator save mat-button mat-button-base">New Chat</button>
    </div> -->

        <span class="chat-header">{{headerText}}</span>
        



    <div class="chat-container">

        <div class="top-fade" [class.theme-light]="params?.theme === 'light'"></div>

        <div class="chat-output">
            
            <div class="chat-output-inner">

                <ng-container *ngFor="let message of chatHistory; let i = index;">
                    <div id="{{'message-'+ i}}" class="box__container">
                        <div class="box__test">
                        <div class="box__avatar">{{userAvatar}}</div>
                        </div>
                        <div class="chat-box" [innerHTML]="message[0]"></div>
                    </div>
                    
                    <div class="box__container">
                        <div class="box__test">
                        <div class="box__avatar box__avatar--response">{{llmAvatar}}</div>
                        </div>
                        <div class="chat-box chat-response" *ngIf="(i !== (chatHistory.length-1)) || (!loading && !error)" [innerHTML]="message[1]"></div>
                        <div class="chat-response" *ngIf="(i === (chatHistory.length-1)) && (loading && !error) ">
                            <div class="loader"></div>
                        </div>
                        <div class="chat-box chat-response" *ngIf="(i === (chatHistory.length - 1)) && error">
                            <p> Something went wrong, please try again. </p>
                        </div>
                    </div>
                  
                   

                </ng-container>

            </div>

        </div>

        <div class="chat-input">

            <div class="bottom-fade" [class.theme-light]="params?.theme === 'light'"></div>

            <form class="input-box" (ngSubmit)="onSubmit($event)">

                <input matInput [(ngModel)]="chatInput" type="email" placeholder="Type a message..." name="chat_input">

                <button mat-raised-button type="submit">
                    <mat-icon>send</mat-icon>
                </button>

            </form>

        </div>

    </div>

</div>
