import { Component, ViewChild, ViewChildren, QueryList, ElementRef, OnInit, OnChanges, ViewEncapsulation } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { map, tap, startWith, debounceTime, mergeMap, take } from 'rxjs/operators';
import { UntypedFormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyRadioButton as MatRadioButton } from '@angular/material/legacy-radio';
import { MatLegacyRadioGroup as MatRadioGroup } from '@angular/material/legacy-radio';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ConfigService } from 'src/app/service/config.service';

export class Event {
  constructor(public id: string, public name: string, public category: string, public categoryId: string, public selected?: boolean) {
    if (selected === undefined) {
      selected = false;
    }
  }
}

export class EventCategory {
  constructor(public id: string, public name: string, public selected?: boolean) {
    if (selected === undefined) {
      selected = false;
    }
  }
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-alerts-setup',
  templateUrl: './alerts-setup.component.html',
  styleUrls: ['./alerts-setup.component.scss']
})
export class AlertsSetupComponent implements OnInit, OnChanges {

  @ViewChild('selectorAlertSchedule', { static: false }) scheduleField: MatSelect;
  @ViewChild('selectorAlertChannel', { static: false }) channelField: MatSelect;
  @ViewChild('selectorAlertType', { static: false }) alertTypeField: MatRadioGroup;
  @ViewChild('selectorAlertObject', { static: false }) alertObjectSelectorField: MatSelect;
  @ViewChild('inputAlertObject', { static: false, read: ElementRef }) alertObjectInputField: ElementRef;

  @ViewChildren('selectorTriggerScore') triggerScoreFields: QueryList<MatSelect>;
  @ViewChildren('selectorTriggerThresholdSetting') triggerThresholdSettingFields: QueryList<MatSelect>;
  @ViewChildren('inputTriggerThreshold') triggerThresholdFields: QueryList<ElementRef>;

  @ViewChild('selectorEventsScore', { static: false }) scoreTypeField: MatSelect;
  @ViewChild('inputEvents', { static: false, read: ElementRef }) eventsInputField: ElementRef;

  @ViewChildren(MatAutocompleteTrigger) autocompleteTriggers: QueryList< MatAutocompleteTrigger>;

  isButtonActive = false;

  fieldsSeen = true;

  currentAlert: any;
  alerts = [];

  notitle = false;

  scoreTypes = [];
  scoreEventsTypes = [];
  thresholdSettingTypes = [];

  selectedTabIndex = 0;
  tabNames = ['Score Alerts', 'Event Alerts'];

  items$: Observable<any>;
  folio$: Observable<any>;

  folio: any;

  loading: boolean;

  loadingAlerts: boolean;

  searchFormControl = new UntypedFormControl();

  eventsControl = new UntypedFormControl();

  subEvents = {};
  subEventsIds = {};
  eventsCategories = {};
  eventsCategoriesIds = {};
  eventsList: any;
  eventsGroupsOptions: any;

  scoreEvents = [];
  scoreCategories = [];

  events = [];
  selectedEvents: Event[] = new Array<Event>();
  selectedEventsCopy: Event[] = new Array<Event>();
  filteredEvents: Observable<Event[]>;
  strictFiltering = false;

  eventCategories = [];
  selectedCategories: EventCategory[] = new Array<EventCategory>();
  filteredEventCategories: Observable<EventCategory[]>;

  closeAfterSelection: boolean;

  lastFilter = '';

  user: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    public snackBar: MatSnackBar,
    private config: ConfigService,
  ) {

    this.user = auth.authToken();

  }

  ngOnInit() {

    this.loadingAlerts = true;
    this.closeAfterSelection = false;

    if (this.auth.scorelabSettings.sentimentAccess) {
      this.scoreTypes.push({
        value: 'sentiment',
        label: 'Sentiment'
      });
      this.scoreEventsTypes.push({
        value: 'sentiment',
        label: 'All'
      });
    }
    if (this.auth.scorelabSettings.generalRiskAccess) {
      this.scoreTypes.push({
        value: 'general_risk',
        label: 'General Risk Score'
      });
      this.scoreEventsTypes.push({
        value: 'general_risk',
        label: 'General Risk Score'
      });
    }
    if (this.auth.scorelabSettings.creditRiskAccess) {
      this.scoreTypes.push({
        value: 'credit_risk',
        label: 'Credit Risk Score'
      });
      this.scoreEventsTypes.push({
        value: 'credit_risk',
        label: 'Credit Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgRiskAccess) {
      this.scoreTypes.push({
        value: 'esg_risk',
        label: 'ESG Risk Score'
      });
      this.scoreEventsTypes.push({
        value: 'esg_risk',
        label: 'ESG Risk Score'
      });
    }
    if (this.auth.scorelabSettings.immediateRiskAccess) {
      this.scoreTypes.push({
        value: 'immediate_risk',
        label: 'Immediate Risk Score'
      });
      this.scoreEventsTypes.push({
        value: 'immediate_risk',
        label: 'Immediate Risk Score'
      });
    }
    if (this.auth.scorelabSettings.esgAccess) {
      this.scoreTypes.push({
        value: 'esg',
        label: 'ESG Score'
      });
      this.scoreEventsTypes.push({
        value: 'esg',
        label: 'ESG Score'
      });
    }
    if (this.auth.scorelabSettings.eb_simAccess) {
      this.scoreTypes.push({
        value: 'eb_sim',
        label: 'EB-SIM Score'
      });
      this.scoreEventsTypes.push({
        value: 'eb_sim',
        label: 'EB-SIM Score'
      });
    }
    if (this.auth.scorelabSettings.bbdAccess) {
      this.scoreTypes.push({
        value: 'bbd',
        label: 'Bull-Bear Daily Score'
      });
    }
    if (this.auth.scorelabSettings.bbwAccess) {
      this.scoreTypes.push({
        value: 'bbw',
        label: 'Bull-Bear Weekly Score'
      });
    }
    if (this.auth.scorelabSettings.bbmAccess) {
      this.scoreTypes.push({
        value: 'bbm',
        label: 'Bull-Bear Monthly Score'
      });
    }
    if (this.auth.scorelabSettings.bbqAccess) {
      this.scoreTypes.push({
        value: 'bbq',
        label: 'Bull-Bear Quarterly Score'
      });
    }
    if (this.auth.scorelabSettings.customScoreAccess && this.auth.scorelabSettings.customScores) {
      this.auth.scorelabSettings.customScores.forEach(item => {
        this.scoreTypes.push({
          value: item.uid,
          label: item.name
        });
        this.scoreEventsTypes.push({
          value: item.uid,
          label: item.name
        });
      });
    }
    if (!this.auth.featureFlags.showEventAlerts) {
      this.tabNames = ['Score Alerts'];
    }
    this.user = this.auth.authToken();

    if (this.user.roles?.includes('ADMIN') || this.user.groups?.includes('ADMIN')) {
      this.thresholdSettingTypes.push({
        value: 'pass_through',
        label: 'Pass Through'
      });
    }

    this.eventsList = JSON.parse(JSON.stringify(this.auth.eventsList));

    this.eventsGroupsOptions = this.eventsList.events.map(el => {

      this.eventsCategories[el.value] = el.label;
      this.eventsCategoriesIds[el.label] = el.value;

      const events = this.eventsList.subEvents[el.value].map(item => {
        return item.label;
      });
      return {
        label: el.label,
        events: events
      };
    });

    Object.entries(this.eventsList.subEvents).forEach(([key, value]) => {
      // @ts-ignore
      value.forEach(item => {
        this.subEvents[item.value] = item.label;
        this.subEventsIds[item.label] = item.value;

        this.events.push(new Event(item.value, item.label, this.eventsCategories[key], key.toString()));

      });
    });

    this.eventsList.events.forEach(event => {
      this.eventCategories.push(new EventCategory(event['value'], event['label']));
    });

    this.filteredEvents = this.eventsControl.valueChanges.pipe(
      startWith<string | Event[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filterEvents(filter))
    );

    this.filteredEventCategories = this.eventsControl.valueChanges.pipe(
      startWith<string | EventCategory[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(filter => this.filterCategories(filter))
    );

    this.notitle = false;
    this.fieldsSeen = true;

    this.currentAlert = {
      uid: 'create',
      name: '',
      active: false,
      alert_type: 'entity',
      alert_category: 'scores',
      schedule: '1d',
      channel: 'email',
      layout: 'digest',
      object_type: '',
      object_id: '',
      object_name: '',
      events: [],
      triggers: [
        {
          score_type: '',
          threshold: '',
          threshold_setting: ''
        }
      ]
    };

    forkJoin([this.auth.getAlerts('score'), this.auth.getAlerts('event')]).subscribe(resArray => {
      const resScore = resArray[0].hits.map(el => Object.assign({}, el, {alert_category: 'scores'}, el.score_alert_metadata));
      const resEvent = resArray[1].hits.map(el => Object.assign({}, el, {alert_category: 'events'}, el.event_alert_metadata));
      // @ts-ignore
      const result = resScore.concat(resEvent);
      result.forEach(item => {
        const alertObject = {
          alert_category: '',
          alert_type: '',
          object_type: '',
          object_id: '',
          object_name: '',
          score_type: '',
          threshold: '',
          threshold_setting: '',
          events: [],
          triggers: []
        };
        alertObject.alert_category = item.alert_category;
        alertObject.alert_type = item.portfolio_id ? 'portfolio' : 'entity';
        alertObject.object_type = item.portfolio_id ? 'portfolio' : item.entities[0].split(':')[0];
        alertObject.object_id = item.portfolio_id ? item.portfolio_id : item.entities[0].split(':')[1];
        if (alertObject.alert_category === 'events') {
          // alertObject.score_type = item.score_type;
          alertObject.score_type = 'sentiment';
          alertObject.threshold = item.threshold;
          alertObject.threshold_setting = item.threshold_setting;
          const events = [...new Set(item.event_ids)];
          alertObject.events = events.filter(e => (this.events.filter(el => (el.id === e)).length > 0)).map(e => {
            // @ts-ignore
            return new Event(e, this.subEvents[e], this.events.filter(el => (el.id === e))[0].category, this.events.filter(el => (el.id === e))[0].categoryId);
          });
        } else if (alertObject.alert_category === 'scores') {
          if (alertObject.alert_type === 'portfolio') {
            const triggerObject = {
              score_type: '',
              threshold: '',
              threshold_setting: ''
            };
            triggerObject.score_type = item.triggers[0]['score_type'];
            if (triggerObject.score_type === 'credit_watch') {
              triggerObject.score_type = 'credit_risk';
            }
            triggerObject.threshold_setting = item.triggers[0].threshold_setting;
            triggerObject.threshold = (triggerObject.score_type === 'sentiment') ? (parseFloat(item.triggers[0].threshold) * 100.0) : item.triggers[0].threshold;
            alertObject.triggers.push(Object.assign({}, item.triggers[0], triggerObject));
          } else {
            item.triggers.forEach(trigger => {
              const triggerObject = {
                score_type: '',
                threshold: '',
                threshold_setting: ''
              };
              triggerObject.score_type = trigger['score_type'];
              if (triggerObject.score_type === 'credit_watch') {
                triggerObject.score_type = 'credit_risk';
              }
              triggerObject.threshold_setting = trigger.threshold_setting;
              triggerObject.threshold = (triggerObject.score_type === 'sentiment') ? (parseFloat(trigger.threshold) * 100.0) : trigger.threshold;
              alertObject.triggers.push(Object.assign({}, trigger, triggerObject));
            });
          }
        }
        this.alerts.push(Object.assign({}, item, alertObject));
      });
      if (this.alerts.length > 0) {
        if (this.alerts.filter(a => a.alert_category === 'scores').length > 0) {
          this.currentAlert = this.alerts.filter(a => a.alert_category === 'scores')[0];
        } else if (this.alerts.filter(a => a.alert_category === 'events').length > 0) {
          if (this.selectedTabIndex === 0) {
            this.currentAlert = {
              uid: 'create',
              name: '',
              active: false,
              alert_type: 'entity',
              alert_category: 'scores',
              schedule: '1d',
              channel: 'email',
              layout: 'digest',
              object_type: '',
              object_id: '',
              object_name: '',
              triggers: [
                {
                  score_type: '',
                  threshold: '',
                  threshold_setting: ''
                }
              ]
            };
          } else {
            this.currentAlert = this.alerts.filter(a => a.alert_category === 'events')[0];
            this.updateSelectedEvents();
          }
        } else {
          this.currentAlert = this.alerts[0];
        }
      }
      this.updateForms();

      this.loadingAlerts = false;

    }, error => {
      this.loadingAlerts = false;
    });

    this.folio$ = this.auth.portFolios();

    this.folio$.subscribe(result => {
      this.folio = result;
    });

  }

  ngOnChanges() {
    if (this.currentAlert) {
      this.updateForms();
    }
  }

  filterEvents(filter: string): Event[] {
    this.lastFilter = filter;
    if (filter) {
      return this.events.filter(option => {
        // return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || option.category.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      return this.events.slice();
    }
  }

  filterCategories(filter: string): Event[] {
    this.lastFilter = filter;
    if (filter) {
      return this.eventCategories.filter(option => {
        let isMatchingSubevent = false;
        this.eventsList.subEvents[option.id].forEach(el => {
          if (el.label.toLowerCase().indexOf(filter.toLowerCase()) >= 0) {
            isMatchingSubevent = true;
          }
        });
        // return isMatchingSubevent;
        return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0 || isMatchingSubevent;
      });
    } else {
      return this.eventCategories.slice();
    }
  }

  removeEvent(event) {
    this.toggleEventSelection(event);
    if (this.filterCategories('').filter(category => (event.categoryId === category.id)).length > 0) {
      this.filterCategories('').filter(category => (event.categoryId === category.id))[0].selected = false;
    }
  }

  displayFnEvents = (value: Event[] | string): string | undefined => {
    // let displayValue: string;
    // if (Array.isArray(value)) {
    //   value.forEach((event, index) => {
    //     if (index === 0) {
    //       displayValue = event.name;
    //     } else {
    //       displayValue += ', ' + event.name;
    //     }
    //   });
    // } else {
    //   displayValue = value;
    // }
    // return displayValue;
    return this.lastFilter;
  }

  // onEventClick(e, event: Event) {
  //   e.stopPropagation();
  //   this.toggleEventSelection(event);
  // }

  // onEventGroupClick(e, group: EventCategory) {
  //   console.log('click');
  // }

  unselectGroup(group: EventCategory) {
    group.selected = false;
  }

  toggleEventSelection(event: Event) {
    event.selected = !event.selected;
    if (event.selected) {
      this.selectedEvents.push(event);
    } else {
      const i = this.selectedEvents.findIndex(value => value.name === event.name && value.category === event.category);
      this.selectedEvents.splice(i, 1);
    }

    this.eventsControl.setValue(this.selectedEvents);
  }

  toggleEventGroupSelection(group: EventCategory, filtered) {
    filtered.pipe(take(1)).subscribe(res => {
      group.selected = !group.selected;
      if (group.selected) {
        this.eventsList.subEvents[group.id].filter(e => this.scoreEvents.includes(e.value)).forEach(event => {
          if (this.selectedEvents.filter(e => e.id === event.value).length === 0) {
            if (res.filter(e => e.id === event.value).length !== 0) {
              const eventCopy = res.filter(e => e.id === event.value)[0];
              eventCopy.selected = true;
              this.selectedEvents.push(eventCopy);
            }
          }
        });
      } else {
        this.eventsList.subEvents[group.id].filter(e => this.scoreEvents.includes(e.value)).forEach(event => {
          if (this.selectedEvents.filter(e => e.id === event.value).length !== 0) {
            if (res.filter(e => e.id === event.value).length !== 0) {
              const eventCopy = res.filter(e => e.id === event.value)[0];
              eventCopy.selected = false;
              const i = this.selectedEvents.findIndex(el => el.id === event.value);
              this.selectedEvents.splice(i, 1);
            }
          }
        });
      }
      this.eventsControl.setValue(this.selectedEvents);
    });
  }

  onTabSelection (event) {
    this.notitle = false;
    this.fieldsSeen = true;
    if (event === 0) {
      this.selectedTabIndex = 0;
      if (this.alerts.filter(a => a.alert_category === 'scores').length > 0) {
        this.currentAlert = this.alerts.filter(a => a.alert_category === 'scores')[0];
      } else {
        this.currentAlert = {
          uid: 'create',
          name: '',
          active: false,
          alert_type: 'entity',
          alert_category: 'scores',
          schedule: '1d',
          channel: 'email',
          layout: 'digest',
          object_type: '',
          object_id: '',
          object_name: '',
          triggers: [
            {
              score_type: '',
              threshold: '',
              threshold_setting: ''
            }
          ]
        };
      }
    } else if (event === 1) {
      this.selectedTabIndex = 1;
      if (this.alerts.filter(a => a.alert_category === 'events').length > 0) {
        this.currentAlert = this.alerts.filter(a => a.alert_category === 'events')[0];
        this.updateSelectedEvents();
      } else {
        this.currentAlert = {
          uid: 'create',
          name: '',
          active: false,
          alert_type: 'entity',
          alert_category: 'events',
          schedule: '1d',
          channel: 'email',
          layout: 'digest',
          object_type: '',
          object_id: '',
          object_name: '',
          score_type: 'sentiment',
          threshold: 5,
          threshold_setting: 'z_score',
          events: []
        };
        this.updateSelectedEvents();
      }
    }
    this.updateForms();
  }

  updateSelectedEvents() {
    const scoreType = this.currentAlert.score_type;
    if (scoreType) {
      this.yukkApi.scoreWeights({}, scoreType).subscribe(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (scoreType === 'sentiment') {
          this.strictFiltering = false;
          this.scoreEvents = JSON.parse(JSON.stringify(this.events.map(el => el.id)));
        } else {
          this.strictFiltering = true;
          this.scoreEvents = res;
        }
        this.scoreCategories = [...new Set(this.events.filter(el => this.scoreEvents.includes(el.id)).map(el => el.categoryId))];
        this.selectedEvents = [];
        this.eventsControl.setValue(this.selectedEvents);
        this.events.forEach(el => {
          el.selected = false;
        });
        this.eventCategories.forEach(el => {
          el.selected = false;
        });
        this.events.forEach(el => {
          if (this.currentAlert.events.filter(e => (e.id === el.id)).length > 0) {
            this.toggleEventSelection(el);
          }
        });
        this.eventsControl.setValue(this.selectedEvents);
      });
    } else {
      this.selectedEvents = [];
      this.eventsControl.setValue(this.selectedEvents);
      this.events.forEach(el => {
        el.selected = false;
      });
      this.eventCategories.forEach(el => {
        el.selected = false;
      });
      this.scoreEvents = [];
      this.scoreCategories = [];
      this.strictFiltering = false;
    }
  }

  updateSelectedEventsWithCopy() {
    this.selectedEvents = [];
    this.eventsControl.setValue(this.selectedEvents);
    this.events.forEach(el => {
      el.selected = false;
    });
    this.eventCategories.forEach(el => {
      el.selected = false;
    });
    this.events.forEach(el => {
      if (this.selectedEventsCopy.filter(e => (e.id === el.id)).length > 0) {
        this.toggleEventSelection(el);
      }
    });
    this.eventsControl.setValue(this.selectedEvents);
  }

  selectAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    const copyAlert = JSON.parse(JSON.stringify(alert));
    this.currentAlert = copyAlert;
    if (this.currentAlert.alert_category === 'events') {
      this.updateSelectedEvents();
    }
    this.updateForms();
  }

  onSlideToggle(isToggled, alert) {
    if (isToggled) {
      this.activateAlert(alert);
    } else {
      this.deactivateAlert(alert);
    }
  }

  activateAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    this.currentAlert = alert;
    this.saveAlert(true, true);
  }

  deactivateAlert(alert) {
    this.notitle = false;
    this.fieldsSeen = true;
    this.currentAlert = alert;
    this.saveAlert(false, false);
  }

  createAlert() {
    this.notitle = false;
    this.fieldsSeen = false;
    if (this.selectedTabIndex === 0) {
      this.currentAlert = {
        uid: 'create',
        name: '',
        active: false,
        alert_type: 'entity',
        alert_category: 'scores',
        schedule: '1d',
        channel: 'email',
        layout: 'digest',
        object_type: '',
        object_id: '',
        object_name: '',
        triggers: [
          {
            score_type: '',
            threshold: '',
            threshold_setting: ''
          }
        ]
      };
    } else if (this.selectedTabIndex === 1) {
      this.currentAlert = {
        uid: 'create',
        name: '',
        active: false,
        alert_type: 'entity',
        alert_category: 'events',
        schedule: '1d',
        channel: 'email',
        layout: 'digest',
        object_type: '',
        object_id: '',
        object_name: '',
        score_type: 'sentiment',
        threshold: 5,
        threshold_setting: 'z_score',
        events: []
      };
      this.updateSelectedEvents();
    }
    this.updateForms();
  }

  isValidAlert(alert, focus = true) {
    if (alert) {
      let isValid = true;
      if (!alert.name.trim()) {
        this.notitle = true;
        isValid = false;
      }
      if (!alert.alert_type) {
        if (this.alertTypeField) {
          // this.alertTypeField.focus();
        }
        isValid = false;
      }
      if (!alert.schedule) {
        if (this.scheduleField && focus) {
          this.scheduleField.focus();
        }
        isValid = false;
      }
      if (!alert.layout) {
        if (this.channelField && focus) {
          this.channelField.focus();
        }
        isValid = false;
      }
      if (!alert.object_type || !alert.object_id) {
        if (this.alertObjectSelectorField && focus) {
          this.alertObjectSelectorField.focus();
        }
        if (this.alertObjectInputField && focus) {
          this.alertObjectInputField.nativeElement.focus();
        }
        isValid = false;
      }
      if (alert.alert_category === 'scores') {
        const alertTriggers = {
          scores: this.triggerScoreFields.toArray(),
          thresholdSettings: this.triggerThresholdSettingFields.toArray(),
          thresholds: this.triggerThresholdFields.toArray()
        };
        alert.triggers.forEach((trigger, index) => {
          if (!trigger.score_type) {
            if (focus) {
              alertTriggers.scores[index].focus();
            }
            isValid = false;
          }
          if (!trigger.threshold) {
            if (focus) {
              alertTriggers.thresholds[index].nativeElement.focus();
            }
            isValid = false;
          }
          if (!trigger.threshold_setting) {
            if (focus) {
              alertTriggers.thresholdSettings[index].focus();
            }
            isValid = false;
          }
        });
      } else if (alert.alert_category === 'events') {
        if (!alert.score_type) {
          if (focus) {
            this.scoreTypeField.focus();
          }
          isValid = false;
        }
        if (!(this.selectedEvents && (this.selectedEvents.length > 0))) {
          if (focus) {
            this.eventsInputField.nativeElement.focus();
          }
          isValid = false;
        }
        if (!alert.threshold && !(alert.threshold === 0)) {
          isValid = false;
        }
        if (!alert.threshold_setting) {
          isValid = false;
        }
      }
      return isValid;
    } else {
      return false;
    }
  }

  saveAlert(isActive, isActivated) {
    this.notitle = false;
    if (this.isValidAlert(this.currentAlert) && this.fieldsSeen) {
      this.currentAlert.active = isActive;
      const payloadObject = {};
      const payloadObjectMetadata = {};
      let isNew;
      if (this.currentAlert.uid === 'create') {
        isNew = true;
      } else {
        isNew = false;
      }
      if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
        payloadObject['uid'] = this.currentAlert.uid;
      }
      payloadObject['active'] = this.currentAlert.active;
      payloadObject['schedule'] = this.currentAlert.schedule;
      payloadObject['channel'] = 'email';
      payloadObject['layout'] = this.currentAlert.layout;
      payloadObject['name'] = this.currentAlert.name;
      if (this.currentAlert.alert_category === 'events') {
        this.currentAlert.events = this.selectedEvents;
      }
      if (this.currentAlert.alert_type) {
        if (this.currentAlert.alert_type === 'entity') {
          payloadObjectMetadata['portfolio_id'] = null;
          payloadObjectMetadata['entities'] = [this.currentAlert.object_type + ':' + this.currentAlert.object_id];
        } else if (this.currentAlert.alert_type === 'portfolio') {
          payloadObjectMetadata['portfolio_id'] = this.currentAlert.object_id;
          payloadObjectMetadata['entities'] = [];
        }
        if (this.currentAlert.alert_type === 'portfolio') {
          if (this.currentAlert.alert_category === 'scores') {
            payloadObjectMetadata['triggers'] = [];
            const payloadObjectTrigger = {};
            if (this.currentAlert.triggers[0].score_type === 'sentiment') {
              payloadObjectTrigger['threshold'] = (this.currentAlert.triggers[0].threshold / 100.0);
            } else {
              payloadObjectTrigger['threshold'] = this.currentAlert.triggers[0].threshold;
            }
            payloadObjectTrigger['threshold_setting'] = this.currentAlert.triggers[0].threshold_setting;
            payloadObjectTrigger['score_type'] = this.currentAlert.triggers[0].score_type;
            if (this.currentAlert.triggers[0].score_type === 'sentiment') {
              payloadObjectTrigger['additional_params'] = {};
            } else if (this.currentAlert.triggers[0].score_type === 'general_risk') {
              payloadObjectTrigger['additional_params'] = {
                with_black_swan: 'true'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'esg_risk') {
              payloadObjectTrigger['additional_params'] = {
                with_black_swan: 'true'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'immediate_risk') {
              payloadObjectTrigger['additional_params'] = {
                with_black_swan: 'true'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'credit_risk') {
              payloadObjectTrigger['additional_params'] = {
                with_black_swan: 'true',
                risk_sensitivity: '0'
              };
              payloadObjectTrigger['score_type'] = 'credit_watch';
            } else if (this.currentAlert.triggers[0].score_type === 'esg') {
              payloadObjectTrigger['additional_params'] = {
                disable_industry_materiality: 'true'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'eb_sim') {
              payloadObjectTrigger['additional_params'] = {};
            } else if (this.currentAlert.triggers[0].score_type === 'bbd') {
              payloadObjectTrigger['additional_params'] = {
                time_horizon: '1D'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'bbw') {
              payloadObjectTrigger['additional_params'] = {
                time_horizon: '1W'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'bbm') {
              payloadObjectTrigger['additional_params'] = {
                time_horizon: '1M'
              };
            } else if (this.currentAlert.triggers[0].score_type === 'bbq') {
              payloadObjectTrigger['additional_params'] = {
                time_horizon: '1Q'
              };
            } else if (this.currentAlert.triggers[0].score_type) {
              payloadObjectTrigger['additional_params'] = {};
              this.auth.scorelabSettings.customScores.forEach(item => {
                if (item.uid === this.currentAlert.triggers[0].score_type) {
                  if (item.sentiment_type === 'neg') {
                    payloadObjectTrigger['additional_params']['with_black_swan'] = 'true';
                  }
                  if (item.event_weights && (item.event_weights.length > 0)) {
                    item.event_weights.forEach(event => {
                      let event_id, weight, polarity, weight_persistence;
                      if (event.event_id) {
                        event_id = event.event_id;
                      } else {
                        event_id = undefined;
                      }
                      if (event.weight) {
                        weight = event.weight;
                      } else {
                        weight = 5;
                      }
                      if (event.polarity) {
                        polarity = event.polarity;
                      } else {
                        polarity = -1;
                      }
                      if (event.weight_persistence) {
                        weight_persistence = event.weight_persistence;
                      } else {
                        weight_persistence = 90;
                      }
                      if (event_id) {
                        payloadObjectTrigger['additional_params'][event_id + '_weight'] = (weight * polarity) + ',' + weight_persistence;
                      }
                    });
                  }
                }
              });
            }
            payloadObjectMetadata['triggers'].push(payloadObjectTrigger);
          } else if (this.currentAlert.alert_category === 'events') {
            // payloadObjectMetadata['score_type'] = this.currentAlert.score_type;
            payloadObjectMetadata['event_ids'] = this.currentAlert.events.map(e => e.id);
            payloadObjectMetadata['threshold'] = this.currentAlert.threshold;
            payloadObjectMetadata['threshold_setting'] = this.currentAlert.threshold_setting;
            if (this.currentAlert.threshold === 0) {
              payloadObjectMetadata['threshold_setting'] = 'pass_through';
            }
            payloadObjectMetadata['additional_params'] = {};
          }
        } else {
          if (this.currentAlert.alert_category === 'scores') {
            payloadObjectMetadata['triggers'] = [];
            this.currentAlert.triggers.forEach(trigger => {
              const payloadObjectTrigger = {};
              payloadObjectTrigger['score_type'] = trigger.score_type;
              if (trigger.score_type === 'sentiment') {
                payloadObjectTrigger['threshold'] = (trigger.threshold / 100.0);
              } else {
                payloadObjectTrigger['threshold'] = trigger.threshold;
              }
              payloadObjectTrigger['threshold_setting'] = trigger.threshold_setting;
              if (trigger.score_type === 'sentiment') {
                payloadObjectTrigger['additional_params'] = {};
              } else if (trigger.score_type === 'general_risk') {
                payloadObjectTrigger['additional_params'] = {
                  with_black_swan: 'true'
                };
              } else if (trigger.score_type === 'esg_risk') {
                payloadObjectTrigger['additional_params'] = {
                  with_black_swan: 'true'
                };
              } else if (trigger.score_type === 'immediate_risk') {
                payloadObjectTrigger['additional_params'] = {
                  with_black_swan: 'true'
                };
              } else if (trigger.score_type === 'credit_risk') {
                payloadObjectTrigger['additional_params'] = {
                  with_black_swan: 'true',
                  risk_sensitivity: '0'
                };
                payloadObjectTrigger['score_type'] = 'credit_watch';
              } else if (trigger.score_type === 'esg') {
                payloadObjectTrigger['additional_params'] = {
                  disable_industry_materiality: 'true'
                };
              } else if (trigger.score_type === 'eb_sim') {
                payloadObjectTrigger['additional_params'] = {};
              } else if (trigger.score_type === 'bbd') {
                payloadObjectTrigger['additional_params'] = {
                  time_horizon: '1D'
                };
              } else if (trigger.score_type === 'bbw') {
                payloadObjectTrigger['additional_params'] = {
                  time_horizon: '1W'
                };
              } else if (trigger.score_type === 'bbm') {
                payloadObjectTrigger['additional_params'] = {
                  time_horizon: '1M'
                };
              } else if (trigger.score_type === 'bbq') {
                payloadObjectTrigger['additional_params'] = {
                  time_horizon: '1Q'
                };
              } else if (trigger.score_type) {
                payloadObjectTrigger['additional_params'] = {};
                this.auth.scorelabSettings.customScores.forEach(item => {
                  if (item.uid === trigger.score_type) {
                    if (item.sentiment_type === 'neg') {
                      payloadObjectTrigger['additional_params']['with_black_swan'] = 'true';
                    }
                    if (item.event_weights && (item.event_weights.length > 0)) {
                      item.event_weights.forEach(event => {
                        let event_id, weight, polarity, weight_persistence;
                        if (event.event_id) {
                          event_id = event.event_id;
                        } else {
                          event_id = undefined;
                        }
                        if (event.weight) {
                          weight = event.weight;
                        } else {
                          weight = 5;
                        }
                        if (event.polarity) {
                          polarity = event.polarity;
                        } else {
                          polarity = -1;
                        }
                        if (event.weight_persistence) {
                          weight_persistence = event.weight_persistence;
                        } else {
                          weight_persistence = 90;
                        }
                        if (event_id) {
                          payloadObjectTrigger['additional_params'][event_id + '_weight'] = (weight * polarity) + ',' + weight_persistence;
                        }
                      });
                    }
                  }
                });
              }
              payloadObjectMetadata['triggers'].push(payloadObjectTrigger);
            });
          } else if (this.currentAlert.alert_category === 'events') {
            // payloadObjectMetadata['score_type'] = this.currentAlert.score_type;
            payloadObjectMetadata['event_ids'] = this.currentAlert.events.map(e => e.id);
            payloadObjectMetadata['threshold'] = this.currentAlert.threshold;
            payloadObjectMetadata['threshold_setting'] = this.currentAlert.threshold_setting;
            if (this.currentAlert.threshold === 0) {
              payloadObjectMetadata['threshold_setting'] = 'pass_through';
            }
            payloadObjectMetadata['additional_params'] = {};
          }
        }
      }
      if (this.currentAlert.alert_category === 'scores') {
        payloadObject['score_alert_metadata'] = payloadObjectMetadata;
      }
      if (this.currentAlert.alert_category === 'events') {
        payloadObject['event_alert_metadata'] = payloadObjectMetadata;
      }
      this.auth.addAlert(payloadObject).subscribe(result => {
        this.currentAlert.uid = result.uid;
        if (isNew) {
          this.alerts.push(this.currentAlert);
        }
        const alertsCopy = JSON.parse(JSON.stringify(this.alerts));
        if (!isNew) {
          alertsCopy.forEach((a, index) => {
            if (a.uid === this.currentAlert.uid) {
              alertsCopy[index] = JSON.parse(JSON.stringify(this.currentAlert));
            }
          });
        }
        this.alerts = [];
        this.alerts = alertsCopy;
        let alertMessage = 'saved';
        if (isActivated === true) {
          alertMessage = 'activated';
        } else if (isActivated === false) {
          alertMessage = 'deactivated';
        }
        this.snackBar.open('Alert ' + alertMessage + '.', 'OK', { duration: 5000 });
      }, error => {
        this.snackBar.open('Saving the Event Alert was not possible. Please reduce the number of Events.', 'OK', { duration: 5000 });
      });
      this.fieldsSeen = true;
    } else {
      if (this.fieldsSeen) {
        alert('Error: all of the required fields must be filled in.');
      }
    }
  }

  deleteAlert() {
    if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
      if (window.confirm('Delete ' + this.currentAlert.name + ' alert?')) {
        // this.notitle = false;
        this.auth.removeAlert(this.currentAlert.uid).subscribe();
        this.alerts = this.alerts.filter(item => {
          return item.uid !== this.currentAlert.uid;
        });
        this.onTabSelection(this.selectedTabIndex);
        this.snackBar.open('Alert deleted.', 'OK', { duration: 5000 });
      }
    }
  }

  addTrigger() {
    this.currentAlert.triggers.push(
      {
        score_type: '',
        threshold: '',
        threshold_setting: ''
      }
    );
  }

  delTrigger(index) {
    this.currentAlert.triggers.splice(index, 1);
  }

  iClose() {
    this.dialog.closeAll();
  }

  optSelect(option) {
    this.searchFormControl.setValue(option.entity.name);
    this.currentAlert.object_type = option.entity.type;
    this.currentAlert.object_id = option.entity.alpha_id;
    this.currentAlert.object_name = option.entity.name;
  }

  optSelect2() {
    this.currentAlert.object_type = '';
    this.currentAlert.object_id = '';
    this.currentAlert.object_name = '';
    this.currentAlert.triggers = [
      {
        score_type: '',
        threshold: '',
        threshold_setting: ''
      }
    ];
    this.searchFormControl.setValue('');
  }

  optSelect3(event, type) {
    let name = '';
    if (type === 'portfolio') {
      name = this.folio.filter(element => {
        return element.uid === event.value;
      })[0].name;
      this.currentAlert.object_type = 'portfolio';
    }
    this.currentAlert.object_name = name;
  }

  updateForms() {
    this.searchFormControl = new UntypedFormControl();
    // this.loading = true;

    let name = '';
    this.searchFormControl.setValue('');

    if (this.currentAlert.object_type && this.currentAlert.object_id) {
      if (this.currentAlert.alert_type === 'entity') {
        this.yukkApi.isentiment(
          {
            type: this.currentAlert.object_type,
            id: this.currentAlert.object_id,
            fromAlertsSetup: true
          },
          false
        ).subscribe(result => {
          this.searchFormControl.setValue(result.entity.name);
          this.currentAlert.object_name = result.entity.name;
        });
      } else if (this.currentAlert.alert_type === 'portfolio') {
        name = this.folio ? this.folio.filter(element => {
          return element.uid === this.currentAlert.object_id;
        })[0].name : '';
        this.currentAlert.object_name = name;
      }
    }

    this.searchFormControl.valueChanges.pipe(debounceTime(1000)).subscribe(value => {
      if ( value && ( value.length > 1 ) ) {
        this.loading = true;
        this.items$ = this.yukkApi.search(value, false).pipe(mergeMap(result => {
          if (result && (result.hits.length === 0)) {
            const words = value.split(' ').filter(item => (item !== ''));
            return this.yukkApi.search(words[0] ? words[0] : ' ', false).pipe(map(result2 => {
              this.loading = false;
              return result2;
            }));
          } else {
            this.loading = false;
            return of(result);
          }
        }));
      }
    });

  }

  onChannelChange() {
    if (this.currentAlert.channel === 'digest') {

    } else if (this.currentAlert.channel === 'explainable_digest') {
      this.currentAlert.triggers = [
        {
          score_type: '',
          threshold: '',
          threshold_setting: ''
        }
      ];
    }
  }

  onScoreChange(event, index) {
    this.currentAlert.triggers[index].threshold_setting = 'cross_above';
    if (event.value === 'sentiment') {
      this.currentAlert.triggers[index].threshold = 50;
    } else if (event.value === 'general_risk') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'esg_risk') {
      this.currentAlert.triggers[index].threshold = 4;
    } else if (event.value === 'immediate_risk') {
      this.currentAlert.triggers[index].threshold = 3.5;
    } else if (event.value === 'credit_risk') {
      this.currentAlert.triggers[index].threshold = 5.5;
    } else if (event.value === 'esg') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'eb_sim') {
      this.currentAlert.triggers[index].threshold = 5;
    } else if (event.value === 'bbd') {
      this.currentAlert.triggers[index].threshold = 0;
    } else if (event.value === 'bbw') {
      this.currentAlert.triggers[index].threshold = 0;
    } else if (event.value === 'bbm') {
      this.currentAlert.triggers[index].threshold = 0;
    } else if (event.value === 'bbq') {
      this.currentAlert.triggers[index].threshold = 0;
    }
  }

  elementMatches(options, value) {
    const filterValue = value.toLowerCase();
    return options.filter(item => item.name.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(element) {
    return (element && element.name) ? element.name : '';
  }

  /**
   *
   */
  iMatch(entity) {
    if (entity.matched_form) {
      return entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0);
    }
  }

  /**
   * format big numbers >999 into K, M, ...
   */
  numberFormat(num) {
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  /**
   * formatting label to be displayed for different events impacts values
   */
  sliderLabel(value: number): string {

    if (value === 0) {
      return 'PASS';
    } else if (value === 1) {
      return 'Very Low';
    } else if (value === 2) {
      return 'Low';
    } else if (value === 3) {
      return 'Medium';
    } else if (value === 4) {
      return 'High';
    } else if (value === 5) {
      return 'Very High';
    }

    return `${value}`;
  }

  onEventsScoreChange(event, clear = false) {
    const changeScore = () => {
      this.yukkApi.scoreWeights({}, event.value).subscribe(response => {
        const res = JSON.parse(JSON.stringify(response));
        if (event.value === 'sentiment') {
          this.strictFiltering = false;
          this.scoreEvents = JSON.parse(JSON.stringify(this.events.map(el => el.id)));
        } else {
          this.strictFiltering = true;
          this.scoreEvents = res;
        }
        this.scoreCategories = [...new Set(this.events.filter(el => this.scoreEvents.includes(el.id)).map(el => el.categoryId))];
        if (clear) {
          this.selectedEvents = [];
          this.eventsControl.setValue(this.selectedEvents);
          this.events.forEach(el => {
            el.selected = false;
          });
          this.eventCategories.forEach(el => {
            el.selected = false;
          });
          this.selectedEventsCopy = JSON.parse(JSON.stringify(this.selectedEvents));
          this.eventsControl.setValue(this.selectedEvents);
        }
      });
    };
    changeScore();
  }

  onAutocompleteOpen(event) {
    this.closeAfterSelection = false;
    this.selectedEventsCopy = JSON.parse(JSON.stringify(this.selectedEvents));
  }

  onAutocompleteClose(event) {
    if (!this.closeAfterSelection) {
      this.updateSelectedEventsWithCopy();
    }
    this.closeAfterSelection = false;
  }

  clearEvents() {
    this.onEventsScoreChange({value: this.currentAlert.score_type}, true);
  }

  selectEvents() {
    this.selectedEventsCopy = JSON.parse(JSON.stringify(this.selectedEvents));
    this.eventsControl.setValue(this.selectedEvents);
    this.closeAfterSelection = true;
    this.closePanels();
  }

  closePanels() {
    this.autocompleteTriggers.forEach(trigger => {
      if (trigger.panelOpen) {
        trigger.closePanel();
      }
    });
  }

  eventsScoreSelectionAlert() {
    // if (this.currentAlert.events.length > 0) {
    //   if (window.confirm('Switching Score will remove all selected Events from the Alert Rule. Do you want to proceed?')) {
    //
    //   } else {
    //     this.scoreTypeField.close();
    //   }
    // }
  }

  triggerAlert() {
    if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
      this.auth.triggerAlert(this.currentAlert.uid).subscribe(res => {
        this.snackBar.open('Alert of id ' + this.currentAlert.uid + ' was triggered, job of id ' + res['job_uid'] + ' was created.', 'OK', { duration: 5000 });
      }, error => {
        this.snackBar.open('Something went wrong.', 'OK', { duration: 5000 });
      });
    }
  }

  getAlertHistory() {
    if (this.currentAlert.uid && (this.currentAlert.uid !== 'create')) {
      this.auth.getAlertHistory(this.currentAlert.uid).subscribe(res => {
        console.log(res);
      }, error => {
        this.snackBar.open('Something went wrong.', 'OK', { duration: 5000 });
      });
    }
  }

  showEventsFilterLabel() {
    if (this.currentAlert.score_type && this.scoreEventsTypes) {
      return this.scoreEventsTypes.filter(el => (el.value === this.currentAlert.score_type)).map(el => el.label)[0];
    } else {
      return '';
    }
  }

  markFieldAsSeen(isVisible: boolean): void {
    if (isVisible) {
      this.fieldsSeen = true;
    }
  }

}
