import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { HttpClient } from '@angular/common/http';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { take, first } from 'rxjs/operators';
import { marked } from 'marked';
import { RoutingService } from '../../service/routing.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {

    chatInput: string;
    chatHistory = [];
    headerText = 'YUKKA Lab News LLM';
    userAvatar = 'YOU';
    llmAvatar = 'YUKKA'
    loading = false;
    error = false;
    params: any;


    constructor(
      public auth: AuthService,
      private http: HttpClient,
      public snackBar: MatSnackBar,
      public routing: RoutingService,
      private route: ActivatedRoute,
    ) {

      this.route.queryParams.subscribe(params => {
        this.params = params;
      });

    }

    ngOnInit(): void {}

    onSubmit(event) {

        if (this.chatInput && (typeof this.chatInput === 'string') && this.chatInput.trim() && !this.loading) {

          
          
          setTimeout(()=> {
            const target = document.getElementById('message-' + (this.chatHistory.length-1));
            target.scrollIntoView();
          }, 500);
          

          this.loading = true;

          const message = this.chatInput.trim(); //question
          const history = JSON.parse(JSON.stringify(this.chatHistory)); //answer

          this.chatInput = '';

          if (!this.error) {
            this.chatHistory.push([marked.parse(message), '']);
          } else {
            this.chatHistory[this.chatHistory.length-1][0] = message;
          }

          this.error = false;

          this.auth.chatLLM(message, history).pipe(first()).subscribe(res => {

            const copyRes = JSON.parse(JSON.stringify(res));
            this.chatHistory[this.chatHistory.length-1][1] = marked.parse(copyRes.history[copyRes.history.length-1][1]);
            this.loading = false;

            // this.chatHistory = copyRes.history.reverse().map(
            //   messages => messages.map(mess => marked.parse(mess))
            // );

            // console.log(this.chatHistory); 
            // console.log(history);
            // console.log(copyRes)


          }, error => {
            this.error = true; 
            this.loading = false;
            this.snackBar.open('Something went wrong. Please try again later.', 'OK', { duration: 10000 });
          });

        }

    }

    /*async myMethod() {
        // Method for https://llm.playground.yukkalab.com/api/chat
        // See the docs at https://llm.playground.yukkalab.com/docs#/default/langchain_completion_api_chat_post
        // The request model for this one is easy:
        // { "message": "mytext", "history": [] }
        // This would give back a response from the LLM like so
        // { "message": "The LLM response", "history": [["mytext", "The LLM response"]] }
        // If you continue to pass the history object it will grow larger over time allowing for conversations
        // TODO: Name everything correctly
        // TODO: Use builtin HTTPClient
        // TODO: Store the chat history over time
        // TODO: Actually make the display pretty :)
        const response = await fetch("https://llm.playground.yukkalab.com/api/chat", {
            body: JSON.stringify({ // body data type must match "Content-Type" header
                    "message": this.textField,
                    "history": []
            }),
            mode: "no-cors", // no-cors, *cors, same-origin
            headers: {
                "Content-type": "application/json"
            },
            method: "POST"
        })
        const data = await response.json()
        this.paragraphText = data.message
    }*/
}
