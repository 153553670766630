import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scanner-main',
  templateUrl: './scanner-main.component.html',
  styleUrls: ['./scanner-main.component.scss']
})
export class ScannerMainComponent {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public routing: RoutingService,
    public auth: AuthService,
  ) { }

}
