<div class="news-tagcloud">
  <!-- <app-news-title *ngIf="titolo$|async as titolo" [titolo]="titolo"></app-news-title> -->
  <div class="tagcloud-cont">
    <div class="nodata" *ngIf="error=='nodata'">NOT ENOUGH DATA</div>
    <div class="nodata" *ngIf="error=='noapi'">Something went wrong. Please try to refresh the page</div>
    <div class="indata" *ngIf="!error">
      <div class="word" *ngFor="let item of items|orderBy:myOrder" [style.color]="item.sentiment|color"
        [style.font-size.px]="item.score|size:tagmin:tagmax" (click)="goTagcloud(item)"
        [class.btnact]="item.tag==tagactive">{{item.word}}</div>
    </div>
  </div>
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
</div>
