import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RedirectGuard } from './guard/redirect.guard';
import { LoginRedirectGuard } from './guard/login-redirect.guard';
import { AuthGuard } from './guard/auth.guard';
import { AdminGuard } from './guard/admin.guard';
import { ReviewGuard } from './guard/review.guard';
import { FolioGuard } from './guard/folio.guard';
import { QueryGuard } from './guard/query.guard';
import { MarketGuard } from './guard/market.guard';
import { SearchGuard } from './guard/search.guard';
import { MobileGuard } from './guard/mobile.guard';
import { NewsGuard } from './guard/news.guard';
import { TrendGuard } from './guard/trend.guard';
import { FiltersGuard } from './guard/filters.guard';
import { HomeGuard } from './guard/home.guard';
import { ScannerGuard } from './guard/scanner.guard';
import { ChatGuard } from './guard/chat.guard';

import { LoginComponent } from './admin/user/login/login.component';
import { CockpitComponent } from './cockpit/cockpit.component';
import { TrendSecsignalComponent } from './cockpit/main-trend/trend-secsignal/trend-secsignal.component';
import { TrendIndicatorComponent } from './cockpit/main-trend/trend-indicator/trend-indicator.component';
import { NetworkComponent } from './cockpit/main-news/news-network/network.component';
import { MainNewsfeedComponent } from './cockpit/main-news/news-newsfeed/news-newsfeed.component';
import { MainNewsComponent } from './cockpit/main-news/main-news.component';
import { TrendMultichartComponent } from './cockpit/main-trend/trend-multichart/trend-multichart.component';
import { MainTrendComponent } from './cockpit/main-trend/main-trend.component';
import { TagcloudComponent } from './cockpit/main-news/news-tagcloud/news-tagcloud.component';
import { MessageComponent } from './admin/user/message/message.component';
import { FeedbackComponent } from './admin/feedback/feedback.component';
import { ReportListComponent } from './admin/report/report-list/report-list.component';
import { AdminComponent } from './admin/admin.component';
import { MobileComponent } from './project/mobile/mobile.component';
import { NewsPortfolioComponent } from './cockpit/main-news/news-portfolio/news-portfolio.component';
import { NewsRadarComponent } from './cockpit/main-news/news-radar/news-radar.component';
import { NewsParticipantsComponent } from './cockpit/main-news/news-participants/news-participants.component';
import { NewsBoardComponent } from './cockpit/main-news/news-board/news-board.component';
import { NewsChartComponent } from './cockpit/main-news/news-chart/news-chart.component';
import { NewsTrendingComponent } from './cockpit/main-news/news-trending/news-trending.component';
import { RecordComponent } from './admin/user/record/record.component';
import { AdminAnnotationComponent } from './admin/annotation/annotation.component';
import { WidgetComponent } from './project/widget/widget.component';
import { WidgetGalleryComponent } from './project/widget/widget-gallery/widget-gallery.component';
import { WidgetIframeComponent } from './project/widget/widget-iframe/widget-iframe.component';
import { PasswordComponent } from './admin/user/password/password.component';
import { NewsChartmapComponent } from './cockpit/main-news/news-chartmap/news-chartmap.component';
import { NewsTreemapComponent } from './cockpit/main-news/news-treemap/news-treemap.component';
import { NewsViewComponent } from './cockpit/main-news/news-newsfeed/news-view/news-view.component';
import { NewsletterComponent } from './project/newsletter/newsletter.component';
import { NewsletterPageComponent } from './project/newsletter/newsletter-page/newsletter-page.component';
import { NewsletterPreviewComponent } from './project/newsletter/newsletter-preview/newsletter-preview.component';
import { NewsletterArticlesComponent } from './project/newsletter/newsletter-articles/newsletter-articles.component';
import { QueryAlertComponent } from './cockpit/main-news/news-query/query-alert/query-alert.component';
import { ChartmapCompanyComponent } from './cockpit/main-news/news-chartmap/chartmap-company/chartmap-company.component';
import { QueryFolioComponent } from './cockpit/main-news/news-query/query-folio/query-folio.component';
import { NewsOutletComponent } from './cockpit/main-news/news-outlet/news-outlet.component';
import { QueryChartComponent } from './cockpit/main-news/news-query/query-chart/query-chart.component';
// import { NewsStoriesComponent } from './cockpit/main-news/news-stories/news-stories.component';
// import { StoriesVisualComponent } from './cockpit/main-news/news-stories/stories-visual/stories-visual.component';
import { BoardEventComponent } from './cockpit/main-news/news-board/board-event/board-event.component';
import { HomepageComponent } from './cockpit/homepage/homepage.component';
import { ScannerComponent } from './cockpit/scanner/scanner.component';
import { StatsComponent } from './project/stats/stats.component';
import { ChatComponent } from './project/chat/chat.component';


const routes: Routes = [

  { path: '', redirectTo: 'cockpit', pathMatch: 'full' },

  { path: 'iframe', redirectTo: 'widget', pathMatch: 'full' },

  // { path: 'portfolio', redirectTo: '/cockpit/news/portfolio', pathMatch: 'full' },
  // { path: 'network', redirectTo: '/cockpit/news/network', pathMatch: 'full' },
  // { path: 'radar', redirectTo: '/cockpit/news/radar', pathMatch: 'full' },
  // { path: 'trend', redirectTo: '/cockpit/trend/secsignal/stoxx_europe_600_auto_parts_trn', pathMatch: 'full' },

  { path: 'logout', redirectTo: '/message/logout', pathMatch: 'full' },

  {

    path: 'cockpit', component: CockpitComponent, canActivate: [MobileGuard, AuthGuard], children: [

      { path: '', redirectTo: 'news', pathMatch: 'full' },

      {
        path: 'home', component: HomepageComponent, canActivate: [RedirectGuard, NewsGuard, FiltersGuard, HomeGuard], children: []
      },

      // {
      //   path: 'scanner', component: ScannerComponent, canActivate: [ScannerGuard], children: []
      // },

      {
        path: 'news', component: NewsOutletComponent, canActivate: [RedirectGuard, NewsGuard, FiltersGuard], children: [

          { path: '', redirectTo: 'portfolio', pathMatch: 'full' },

          { path: 'board', redirectTo: 'market', pathMatch: 'full' },

          {
            path: 'market', component: MainNewsComponent, canActivate: [MarketGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', redirectTo: 'main', pathMatch: 'full' },
              { path: 'main', component: NewsBoardComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              // { path: 'network', component: NetworkComponent },
              { path: 'audit', component: NewsChartmapComponent }
            ]
          },

          {
            path: 'portfolio', component: MainNewsComponent, canActivate: [RedirectGuard, ScannerGuard, FolioGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', redirectTo: 'scanner', pathMatch: 'full' },
              { path: 'scanner', component: ScannerComponent },
              { path: 'main', component: NewsPortfolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              { path: 'network', component: NewsChartmapComponent },
              { path: 'audit', component: NewsChartmapComponent }
            ]
          },

          {
            path: 'query', component: MainNewsComponent, canActivate: [QueryGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', redirectTo: 'main', pathMatch: 'full' },
              { path: 'main', component: QueryFolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
              { path: 'audit', component: NewsChartmapComponent }
            ]
          },

          {
            path: 'search', component: MainNewsComponent, canActivate: [SearchGuard], children: [
              { path: '', redirectTo: 'chart', pathMatch: 'full' },
              { path: 'main', component: NewsChartmapComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
              { path: 'audit', component: NewsChartmapComponent }
            ]
          },

          /*{
            path: 'trending', component: MainNewsComponent, canActivate: [], children: [
              { path: '', component: NewsTrendingComponent },
            ]
          },*/

        ]
      },

      /*{
        path: 'stories', component: NewsStoriesComponent, canActivate: [], children: [
          { path: '', redirectTo: 'donald', pathMatch: 'full' },
          { path: ':id', component: StoriesVisualComponent },
        ]
      },*/

      {
        path: 'invest', component: MainTrendComponent, canActivate: [TrendGuard], children: [
          { path: '', redirectTo: 'signal', pathMatch: 'full' },
          /*{ path: 'secsignal', canActivate: environment.guards.trend, component: TrendSecsignalComponent },*/
          { path: 'signal', canActivate: [TrendGuard], component: TrendIndicatorComponent },
          /*{ path: 'multichart', canActivate: environment.guards.trend, component: TrendMultichartComponent },*/
          // {
          //   path: 'trending', component: MainNewsComponent, canActivate: [], children: [
          //     { path: '', component: NewsTrendingComponent },
          //   ]
          // }
          { path: 'trending', component: NewsTrendingComponent }
        ]
      },

      {
        path: 'chat', component: ChatComponent, canActivate: [ChatGuard], children: []
      },

      {
        path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuard], children: [
          { path: '', redirectTo: 'preview', pathMatch: 'full' },
          { path: 'preview', component: NewsletterPreviewComponent },
          // { path: 'articles', component: NewsletterArticlesComponent },
          // { path: 'livepreview', component: NewsletterPreviewComponent },
        ]
      },

      {
        path: 'alerts', component: QueryAlertComponent, canActivate: [AuthGuard], children: []
      }

    ]
  },

  {
    path: 'mobile', canActivate: [AuthGuard], component: MobileComponent, children: [

      // { path: '', redirectTo: 'news/portfolio', pathMatch: 'full' },
      // { path: 'news', redirectTo: 'news/portfolio', pathMatch: 'full' },
      // { path: 'trend', redirectTo: 'trend/secsignal', pathMatch: 'full' },
      // { path: 'news/market', component: NewsBoardComponent },
      // { path: 'news/chartmap', component: NewsChartmapComponent },
      // { path: 'news/newsfeed', component: MainNewsfeedComponent },
      // { path: 'news/portfolio', component: NewsPortfolioComponent },
      // { path: 'news/network', component: NetworkComponent },
      // { path: 'news/topicloud', component: TagcloudComponent },
      // { path: 'news/radar', component: NewsRadarComponent },
      // { path: 'trend/secsignal', component: TrendSecsignalComponent },
      // { path: 'trend/indicator', component: TrendIndicatorComponent },

      { path: 'invest', redirectTo: 'invest/signal', pathMatch: 'full' },

      { path: 'invest/signal', component: TrendIndicatorComponent },

      { path: 'invest/trending', component: NewsTrendingComponent },

      { path: '', redirectTo: 'news/portfolio', pathMatch: 'full' },

      {
        path: 'news', component: NewsOutletComponent, canActivate: [NewsGuard], children: [

          {
            path: 'market', component: NewsOutletComponent, canActivate: [MarketGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', component: NewsBoardComponent },
              { path: 'chart', component: NewsChartmapComponent },
              { path: 'newsfeed', component: MainNewsfeedComponent },
              { path: 'radar', component: NewsRadarComponent },
              // { path: 'network', component: NetworkComponent },
            ]
          },

          {
            path: 'portfolio', component: MainNewsComponent, canActivate: [ScannerGuard, FolioGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', redirectTo: 'scanner', pathMatch: 'full' },
              { path: 'scanner', component: ScannerComponent },
              { path: 'newsfeed', component: MainNewsfeedComponent },
              { path: 'main', component: NewsPortfolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
            ]
          },

          {
            path: 'query', component: NewsOutletComponent, canActivate: [QueryGuard], runGuardsAndResolvers: 'always', children: [
              { path: '', component: QueryFolioComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'chart', component: NewsChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
            ]
          },

          {
            path: 'search', component: NewsOutletComponent, canActivate: [SearchGuard], children: [
              { path: '', component: QueryChartComponent },
              { path: 'chart', component: NewsChartComponent },
              { path: 'chart2', component: QueryChartComponent },
              { path: 'radar', component: NewsRadarComponent },
              { path: 'participants', component: NewsParticipantsComponent },
              // { path: 'network', component: NetworkComponent },
            ]
          },

        ]
      },

      {
        path: 'trending', component: NewsOutletComponent, canActivate: [], children: [
          { path: '', component: NewsTrendingComponent },
        ]
      },


    ],
  },

  {
    path: 'admin', component: AdminComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'report', pathMatch: 'full' },
      { path: 'report', canActivate: [ReviewGuard], component: ReportListComponent },
      { path: 'feedback', canActivate: [ReviewGuard], component: FeedbackComponent, },
      { path: 'annotation', canActivate: [ReviewGuard], component: AdminAnnotationComponent, },
    ],
  },

  {
    path: 'stats', component: StatsComponent, canActivate: [AuthGuard, AdminGuard]
  },

  {
    path: 'widget', component: WidgetComponent, canActivate: [AuthGuard, RedirectGuard], children: [
      { path: 'gallery', component: WidgetGalleryComponent },
      {
        path: 'portfolio', component: WidgetIframeComponent, canActivate: [AuthGuard, FolioGuard], runGuardsAndResolvers: 'always', children: [
          { path: '', component: NewsPortfolioComponent },
          { path: 'chart', component: NewsChartComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'network', component: NetworkComponent },
        ],
      },
      {
        path: 'query', component: WidgetIframeComponent, canActivate: [AuthGuard, QueryGuard], runGuardsAndResolvers: 'always', children: [
          { path: '', component: QueryFolioComponent },
          { path: 'chart', component: QueryChartComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'network', component: NetworkComponent },
        ],
      },
      {
        path: '', component: WidgetIframeComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always', children: [
          { path: 'newsfeed', component: MainNewsfeedComponent },
          { path: 'chart', component: NewsChartComponent },
          { path: 'treemap', component: NewsTreemapComponent },
          { path: 'company', component: ChartmapCompanyComponent },
          // { path: 'network', component: NetworkComponent },
          { path: 'trending', component: NewsTrendingComponent },
          { path: 'topicloud', component: TagcloudComponent },
          { path: 'radar', component: NewsRadarComponent },
          { path: 'participants', component: NewsParticipantsComponent },
          // { path: 'secsignal', component: TrendSecsignalComponent },
          { path: 'signal', component: TrendIndicatorComponent },
          { path: 'event', component: BoardEventComponent },
        ],
      },
      { path: 'reader', component: WidgetIframeComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
      { path: 'story', component: WidgetIframeComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
    ],
  },

  // { path: 'newsletter', component: NewsletterComponent, canActivate: [AuthGuard] },
  // { path: 'newsletter/:id', component: NewsletterComponent, canActivate: [AuthGuard] },

  { path: 'login', component: LoginComponent, canActivate: [LoginRedirectGuard] },
  // { path: 'registration', component: RecordComponent },
  { path: 'password', component: PasswordComponent },
  { path: 'password/:id', component: PasswordComponent },
  { path: 'message/:id', component: MessageComponent },

  { path: '**', redirectTo: 'message/error404', pathMatch: 'full' }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
