<div class="treemap-template">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="contindex">
    <div class="indexname" *ngFor="let item of treemapdata | orderBy: myOrder | diff: myArray"
      [style.color]="item.score | color">
      <div class="indexcont">
        <div class="tito box-map" (click)="goMap(item.entity)">
          <div class="back" [style.background-color]="item.score | color"></div>

          <div class="tino">
            <img *ngIf="(params.id === 'country') && item.entity.media" [src]="item.entity.media.uni" alt="" style="width: 20px; margin-right: 10px;">
            <span>{{item.entity.name | nicetxt | isbeta}}</span>
          </div>
          <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'||!params.sort">
            <div class="percent">
              <span class="brak">(</span>{{item.score | percent}}<span class="brak">)</span>
            </div>
            <span class="numb">
              <span class="brak">(</span>
              <span>{{item.score_delta*100|round|trend}}</span>
              <span>{{item.score_delta*100|round|negative}}</span>
              <span class="brak">)</span>
            </span>
          </div>
        </div>
        <div class="subo">
          <div class="text box-map"
            *ngFor="let subitem of item.children | orderBy: myOrder | initial: item.children.length-3"
            [style.color]="subitem.score | color" (click)="goMap(subitem.entity)">
            <div class="back" [style.background-color]="subitem.score | color"></div>
            <div class="tino">{{subitem.entity.name | nicetxt}}</div>
            <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'">
              <div class="percent">
                <span class="brak">(</span>
                {{subitem.score | percent}}
                <span class="brak">)</span>
              </div>
              <span class="numb">
                <span class="brak">(</span>
                <span>{{subitem.score_delta*100|round|trend}}</span>
                <span>{{subitem.score_delta*100|round|negative}}</span>
                <span class="brak">)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--<div class="contindex" *ngIf="auth.scorelabSettings.defaultScore !== 'sentiment'">
    <div class="indexname" *ngFor="let item of treemapdata | orderBy: myOrder | diff: myArray"
         [style.color]="(this.auth.getScoreType() === 'pos') ? (item.score | colorScore2) : (item.score | colorScore)">
      <div class="indexcont">
        <div class="tito box-map" (click)="goMap(item.entity)">
          <div class="back" [style.background-color]="(this.auth.getScoreType() === 'pos') ? (item.score | colorScore2) : (item.score | colorScore)"></div>

          <div class="tino">
            <img *ngIf="(params.id === 'country') && item.entity.media" [src]="item.entity.media.uni" alt="" style="width: 20px; margin-right: 10px;">
            <span>{{item.entity.name | nicetxt | isbeta}}</span>
          </div>
          <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'||!params.sort">
            <div class="percent">
              <span class="brak">(</span>{{item.score|round: 1}}<span class="brak">)</span>
            </div>
            <span class="numb">
              <span class="brak">(</span>
              <span>{{item.score_delta|round: 2|trend}}</span>
              <span>{{item.score_delta|round: 2|negative}}</span>
              <span class="brak">)</span>
            </span>
          </div>
        </div>
        <div class="subo">
          <div class="text box-map"
               *ngFor="let subitem of item.children | orderBy: myOrder | initial: item.children.length-3"
               [style.color]="(this.auth.getScoreType() === 'pos') ? (subitem.score | colorScore2) : (subitem.score | colorScore)" (click)="goMap(subitem.entity)">
            <div class="back" [style.background-color]="(this.auth.getScoreType() === 'pos') ? (subitem.score | colorScore2) : (subitem.score | colorScore)"></div>
            <div class="tino">{{subitem.entity.name | nicetxt}}</div>
            <div class="info" [class.trend]="params.sort==='trend'||params.sort==='-trend'">
              <div class="percent">
                <span class="brak">(</span>
                {{subitem.score | round: 1}}
                <span class="brak">)</span>
              </div>
              <span class="numb">
                <span class="brak">(</span>
                <span>{{subitem.score_delta|round: 2|trend}}</span>
                <span>{{subitem.score_delta|round: 2|negative}}</span>
                <span class="brak">)</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
</div>
