<h1 mat-dialog-title class="report-title">{{data.title}}</h1>
<div mat-dialog-content class="report-content">
    <div class="report-thumbnail">
      <img *ngIf="(data.type === 'general_risk')" src="../../../assets/images/GR_report_thumbnail.png">
      <img *ngIf="(data.type === 'immediate_risk')" src="../../../assets/images/IR_report_thumbnail.png">
      <img *ngIf="(data.type === 'eb_sim')" src="../../../assets/images/EBSIM_report_thumbnail.png">
      <img *ngIf="(data.type === 'esg')" src="../../../assets/images/ESG_report_thumbnail.png">
      <img *ngIf="(data.type === 'esg_risk')" src="../../../assets/images/ESGR_report_thumbnail.png">
      <img *ngIf="(data.type === 'credit_risk')" src="../../../assets/images/CR_report_thumbnail.png">
    </div>
    <div class="report-info">
      <p *ngIf="(data.type === 'general_risk')">
        Broaden your risk perspective with our General Risk Score Report: Explore essential insights into Overall Score Trends, Key Risk Indicators and Peer Group Comparisons.
      </p>
      <p *ngIf="(data.type === 'immediate_risk')">
        Navigate risks swiftly with our Immediate Risk Score Report: Access key insights into Urgent Risk Factors, Score Dynamics, and Peer Group comparisons.
      </p>
      <p *ngIf="(data.type === 'eb_sim')">
        Enhance decision-making with our custom EB-SIM Score Report, a comprehensive Summary of Score Trends, Major Events and Peer Group Comparisons.
      </p>
      <p *ngIf="(data.type === 'esg')">
        Stay ahead of the ESG curve. Our Company Report unveils Top Stories, Score Trends, and Industry Benchmarks, offering unparalleled insights into companies' ESG Exposure.
      </p>
      <p *ngIf="(data.type === 'esg_risk')">
        Our ESG Risk Company Report unveils Top Stories, Score Trends, and Industry Benchmarks, offering unparalleled insights into companies' ESG Risk Exposure.
      </p>
      <p *ngIf="(data.type === 'credit_risk')">
        Master Credit Risk analysis with our comprehensive Report: Unearth vital insights into Top Risks, Score Trends, and Industry Benchmarks for strategic advantage.
      </p>
    </div>
</div>
<div mat-dialog-actions class="report-actions">
    <button mat-button [disabled]="reportStatus === 'download_quota_exceeded'" [class.report-disabled]="reportStatus === 'download_quota_exceeded'" [matTooltip]="(reportStatus === 'download_quota_exceeded') ? 'Monthly Report Download Limit Exceeded' : ''" (click)="onClick()">Get the Report</button>
    <!--<button mat-button *ngIf="reportStatus === 'available'" (click)="onClick2(reportUrl)" matTooltip="Report was already generated, click to open report in a new tab">Get the Report</button>-->
</div>
