<div class="trending-main" *ngIf="trending">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div>
  <ng-container *ngIf="!(loading || nodata)">
    <div class="titolo">
      <div class="border">
        <div class="bar" [style.background-color]="'transparent'" [style.color]="'transparent'"></div>
      </div>
      <div class="box-trend">
        <div class="tito">
          <div class="cont">
            <span class="value logo header"></span>
            <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">
              <span class="sort" (click)="goSort('abc')" matTooltip="Alphabetic Order" matTooltipPosition="above">Company </span>
              <span [class.no-sort]="!(params.sort==='abc'||params.sort==='-abc')">
                {{((params.sort==='abc') ? -1 : 1 )|trend}}
              </span>
            </span>
          </div>
        </div>
        <div class="info">
          <div class="cont">
            <span *ngIf="iTool('Country')" class="value">
              <span class="sort">Country </span>
            </span>
            <span *ngIf="iTool('Trend')" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
              <span class="sort" (click)="goSort('trend')">Bull/Bear </span>
              <span [class.no-sort]="!(params.sort==='trend'||params.sort==='-trend')">
                {{((params.sort==='trend') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Volatility')" class="value" [class.sort-active]="params.sort==='vola'||params.sort==='-vola'">
              <span class="sort" (click)="goSort('vola')" matTooltip="Volatility">Vola </span>
              <span [class.no-sort]="!(params.sort==='vola'||params.sort==='-vola')">
                {{((params.sort==='vola') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('Price')" class="value">
              <span class="sort">Price(USD) </span>
            </span>
            <span *ngIf="iTool('Price Change')" class="value" [class.sort-active]="params.sort==='price'||params.sort==='-price'">
              <span class="sort" (click)="goSort('price')">ΔPrice </span>
              <span [class.no-sort]="!(params.sort==='price'||params.sort==='-price')">
                {{((params.sort==='price') ? 1 : -1 )|trend}}
              </span>
            </span>
            <span *ngIf="iTool('ESG Score')" class="value" [class.sort-active]="params.sort==='score'||params.sort==='-score'">
              <span class="sort" (click)="goSort('score')">ESG </span>
              <span [class.no-sort]="!(params.sort==='score'||params.sort==='-score')">
                {{((params.sort==='score') ? 1 : -1 )|trend}}
              </span>
            </span>

            <span class="value edit header-menu" (click)="menuFilter.open()">
              <mat-icon matTooltip="Adjust columns to be displayed">more_vert</mat-icon>
              <mat-select [formControl]="ifilter" multiple (openedChange)="iFilter($event)" #menuFilter>
                <mat-option *ngFor="let filter of array_filter" [value]="filter">{{filter}}</mat-option>
              </mat-select>
            </span>

          </div>
        </div>
      </div>
    </div>
    <div class="item-cont">
      <div class="box-trend" *ngFor="let item of trending | search:'name,isin':searchTerm | orderBy: myOrder" [class.active]="qparams.tag == ('m:' + item.type + ':' + item.alpha_id)" (click)="addTag(item)">
        <div class="tito">
          <div class="cont">
            <span class="value logo">
              <img *ngIf="item.logo" [src]="item.logo">
              <span *ngIf="!item.logo" class="logo-default">{{item.name.charAt(0)}}</span>
            </span>
            <span class="value" [class.sort-active]="params.sort==='abc'||params.sort==='-abc'">{{item.name}}</span>
          </div>
        </div>
        <div class="info">
          <div class="cont">
            <span *ngIf="iTool('Country')" class="value" [matTooltip]="item.country_name">
              <span>{{item.country_id}}</span>
            </span>
            <span *ngIf="iTool('Trend')" class="value" [class.sort-active]="params.sort==='trend'||params.sort==='-trend'">
              <span class="icon">
                <img *ngIf="item.trend_type === 'bear'" src="assets/icon/bear.svg">
                <img *ngIf="item.trend_type === 'bull'" src="assets/icon/bull.svg">
              </span>
              <span [class.bear]="item.trend_type === 'bear'" [class.bull]="item.trend_type === 'bull'">{{item.trend_value + '%'}}</span>
            </span>
            <span *ngIf="iTool('Volatility')" class="value" [class.sort-active]="params.sort==='vola'||params.sort==='-vola'">
              <!--<span class="icon">
                <img *ngIf="item.volatility_type === 'dolphin'" src="assets/icon/dolphin.svg">
                <img *ngIf="item.volatility_type === 'whale'" src="assets/icon/whale.svg">
              </span>-->
              <span [class.dolphin]="item.volatility_type === 'dolphin'" [class.whale]="item.volatility_type === 'whale'">{{item.volatility_value + '%'}}</span>
            </span>
            <span *ngIf="iTool('Price')" class="value">
              <span>{{item.price | round: 2}}</span>
            </span>
            <span *ngIf="iTool('Price Change')" class="value" [class.sort-active]="params.sort==='price'||params.sort==='-price'">
              <span>{{((item.price_delta > 0) ? '+' : '') + item.price_delta + '%'}}</span>
            </span>
            <span *ngIf="iTool('ESG Score')" class="value" [class.sort-active]="params.sort==='score'||params.sort==='-score'">
              <span>{{item.score}}</span>
            </span>

            <span class="value edit" [class.transparent]="true">
              <mat-icon>mode_edit</mat-icon>
            </span>

          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
