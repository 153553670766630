/**
* Hierarchy production/stage project
*/
export const yukka = [
  // { label: 'Country', type: 'hierarchy', id: 'regions_indices' },
  { label: 'Industry', type: 'hierarchy', id: 'icb' },
  { label: 'Event', type: 'event', id: 'super_event' },
];

/**
* Hierarchy develop project
*/
export const dev = [
  // { label: 'Country', type: 'hierarchy', id: 'regions_indices' },
  { label: 'Industry', type: 'hierarchy', id: 'industry' },
  { label: 'Country', type: 'hierarchy', id: 'country' },
  { label: 'Index', type: 'hierarchy', id: 'index' },
  // { label: 'Automotive', type: 'hierarchy', id: 'automotive_overview' },
  // { label: 'Event', type: 'event', id: 'super_event' },
];

/**
* Hierarchy iframe project
*/
export const iframe = [
  // { label: 'Country', type: 'hierarchy', id: 'regions_indices' },
  { label: 'Industry', type: 'hierarchy', id: 'icb' },
  // { label: 'Automotive', type: 'hierarchy', id: 'automotive_overview' },
  // { label: 'Coverage Am', type: 'hierarchy', id: 'coverage_am' },
  // { label: 'Coverage Fi', type: 'hierarchy', id: 'coverage_fi' },
  // { label: 'Peergroup Fi', type: 'hierarchy', id: 'peergroup_fi' },
];
