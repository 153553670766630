import {Component, OnInit, ViewEncapsulation, Input, Inject} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';
import { ConfigService } from 'src/app/service/config.service';
import * as FileSaver from 'file-saver';
import * as _ from 'lodash';

/**
 * This component is used in the top menu search and it use the query2-group component
 * The others query1-group and query-group component was used for a better visualization
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-query',
  templateUrl: './query-dialog.component.html',
  styleUrls: ['./query-dialog.component.scss']
})
export class QueryDialogComponent {

  queryForm: UntypedFormGroup;
  namequery: UntypedFormGroup;
  params: any;
  query: string;
  $querylist: Observable<any>;
  myquery: any;
  insave: boolean;
  update: boolean;
  itab = 3;
  defaultSlop = 5;
  queryNew = 'Create New Query';
  queryTitle: string;
  savedQuery: any;

  // adding queries to collections - options
  selectedCollections = [];
  collectionsList = [];

  /**
   * subscribe to the query parameter and initialize the query
   */
  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    public snackBar: MatSnackBar,
    public auth: AuthService,
    public routing: RoutingService,
    public config: ConfigService,
    @Inject(MAT_DIALOG_DATA) public queryInput,
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = params;
    });

    this.auth.getGroupsQuery().subscribe(result => {
      this.collectionsList = result;
    });

    if (queryInput && (queryInput.type === 'searchValue')) {
      this.queryTitle = this.queryNew;
      const fullQuery = {
        'operator': 'and',
        'filters': [],
        'groups': [
          {
            'operator': 'or',
            'slop': 5,
            'filters': [
              {
                'negated': false,
                'field': 'text',
                'name': '',
                'value': queryInput.value,
                'input': queryInput.value
              }
            ],
            'groups': []
          }
        ]
      };
      this.inEdit(fullQuery);
    } else if (queryInput && (queryInput.type === 'searchQuery')) {
      this.queryTitle = this.queryNew;
      const fullQuery = {
        'operator': queryInput.query.operator ? queryInput.query.operator : 'and',
        'filters': queryInput.query.filters ? queryInput.query.filters : [],
        'groups': queryInput.query.groups ? queryInput.query.groups : []
      };
      this.inEdit(fullQuery);
    } else if (this.auth.query) {
      if (this.auth.query.type === 'search') {
        this.queryTitle = this.auth.query.name;
        this.inEdit(this.auth.query.query);
      } else {
        this.inStart();
      }
    } else {
      this.inStart();
    }

    this.namequery = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
    });

    // this.getQuery();

  }

  /**
   * requery specific query by id
   */
  // getQuery() {
  //   this.$querylist = this.auth.getQuery();
  //   this.auth.getQuery().subscribe(result => {
  //     if (this.params.queryid) {
  //       result.map(query => {
  //         if (query.uid === this.params.queryid) {
  //           this.myquery = query;
  //           this.namequery.setValue({ name: query.name });
  //         }
  //       });
  //     }
  //   });
  // }

  /**
   * initializate the query
   */
  inStart() {
    this.queryTitle = this.queryNew;
    this.savedQuery = null;
    if (this.namequery) {
      this.namequery.setValue({ name: '' });
    }
    this.queryForm = this.formBuilder.group({
      operator: new UntypedFormControl('and'),
      filters: new UntypedFormArray([]),
      groups: new UntypedFormArray([])
    });
    this.addGroup();
  }

  /**
   * update the saved query under the same id
   */
  inUpdate() {
    this.insave = true;
    this.update = true;
  }

  /**
   * save the query under the new or different id
   */
  inSave() {
    this.insave = true;
    this.update = false;
  }

  /**
   * edit a specific query
   */
  inEdit(query) {
    const filters = query.filters.map(item => this.myRule2(item));
    this.queryForm = this.formBuilder.group({
      operator: new UntypedFormControl(query.operator),
      filters: new UntypedFormArray(filters),
      groups: new UntypedFormArray(this.initGroup(query.groups))
    });
  }

  /**
   * initial group query
   */
  initGroup(groups) {
    if (groups) {
      return groups.map(item1 => {
        const filters1 = item1.filters.map(item => this.myRule2(item));
        return this.formBuilder.group({
          operator: new UntypedFormControl(item1.operator),
          slop: new UntypedFormControl(item1.slop),
          filters: new UntypedFormArray(filters1),
          groups: new UntypedFormArray([])
        });
      });
    }
    return [];
  }

  /**
   * add a group query
   */
  addGroup() {
    const filters = this.formBuilder.array([this.myRule()]);
    const groups = this.formBuilder.group({
      operator: 'or',
      slop: this.defaultSlop,
      filters: filters,
      groups: this.formBuilder.array([])
    });
    const mygroup = this.queryForm.get('groups') as UntypedFormArray;
    mygroup.push(groups);
  }

  /**
   * duplicate a group query
   */
  duplicateGroup(index) {
    const groups = this.queryForm.get('groups') as UntypedFormArray;
    const groupCopy = _.cloneDeep(groups.at(index)) as UntypedFormGroup;
    groups.insert(index, groupCopy);
  }

  /**
   * delete a group query
   */
  delGroup(index) {
    const groups = this.queryForm.get('groups') as UntypedFormArray;
    groups.removeAt(index);
  }

  /**
   * add a rule
   */
  addRule(index, level) {
    if (level === 0) {
      const filters = this.queryForm.get('filters') as UntypedFormArray;
      filters.push(this.myRule());
    } else {
      const groups = this.queryForm.get('groups') as UntypedFormArray;
      const filters = groups.at(index).get('filters') as UntypedFormArray;
      filters.push(this.myRule());
    }
  }

  /**
   * delete a rule
   */
  delRule(level, index1, index2) {
    if (level === 0) {
      const filters = this.queryForm.get('filters') as UntypedFormArray;
      filters.removeAt(index2);
    } else {
      const groups = this.queryForm.get('groups') as UntypedFormArray;
      const filters = groups.at(index1).get('filters') as UntypedFormArray;
      filters.removeAt(index2);
    }
  }

  /**
   * edit a rule
   */
  myRule() {
    return this.formBuilder.group({
      negated: new UntypedFormControl(false, Validators.required),
      field: new UntypedFormControl('entity', Validators.required),
      name: new UntypedFormControl(''),
      value: new UntypedFormControl('', Validators.required),
      input: new UntypedFormControl('', Validators.required)
    });
  }

  /**
   * edit a rule
   */
  myRule2(rule) {
    return this.formBuilder.group({
      negated: new UntypedFormControl(rule.negated, Validators.required),
      field: new UntypedFormControl(rule.field, Validators.required),
      name: new UntypedFormControl(rule.name),
      value: new UntypedFormControl(rule.value, Validators.required),
      input: new UntypedFormControl(rule.input)
    });
  }

  /**
   * redirect to the chart query
   */
  goView() {
    this.auth.query = {
      type: 'search',
      name: this.queryTitle,
      query: this.queryForm.value
    };
    if (this.config.appConfig.setup.useStorage) {
      localStorage.setItem('search', JSON.stringify(this.auth.query));
    }
    this.matDialog.closeAll();
    let currentView = location.pathname.split('/')[4];
    if (!currentView || currentView === 'main' || currentView === 'scanner') {
      currentView = 'chart';
    }
    this.router.navigate(['/cockpit/news/search/' + currentView], {
      queryParams: {
        id: Math.random(),
        portfolioId: null,
        groupId: null,
        type: null,
        eventid: null,
        eventype: null,
        scoreevent: null,
        flagevent: null,
        factuality: null,
        temporality: null,
        newsaudit: null,
        tag: null,
        participant: null,
        score: null,
        time: this.params.score ? null : this.params.time,
      },
      queryParamsHandling: 'merge'
    });
  }

  /**
   * visualizate the query
   */
  viewQuery(query) {
    this.savedQuery = query;
    this.selectedCollections = query.groups;
    this.queryTitle = query.name;
    this.namequery.setValue({ name: query.name });
    this.itab = 3;
    this.auth.query = query.query;
    this.inEdit(this.auth.query);
  }

  /**
   * delete the entire query
   */
  // delQuery(uid) {
  //   if (window.confirm('Do you want to delete this query?')) {
  //     this.auth.delQuery(uid).subscribe(() => {
  //       this.getQuery();
  //     });
  //   }
  // }

  /**
   * import query from file
   */
  importQuery(event) {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const result = JSON.parse(e.target.result);
        this.viewQuery(result);
        this.savedQuery = null;
      };
      reader.readAsText(event.target.files[0]);
    }
  }

  /**
   * save query as a json format file
   */
  exportQuery(query) {
    const queryPure = {
      name: query.name,
      query: query.query
    };
    const queryString = JSON.stringify(queryPure);
    const blob = new Blob([queryString], {type: 'application/json'});
    FileSaver.saveAs(blob, 'query_' + queryPure.name + '.json');
  }

  /**
   * save the query
   */
  saveQuery() {
    if (this.namequery.valid) {
      this.insave = false;
      const data = {
        groups: this.selectedCollections,
        name: this.namequery.value.name,
        query: this.queryForm.value
      };
      if (this.savedQuery && this.update) {
        data['uid'] = this.savedQuery.uid;
      }
      this.auth.addQuery(data).subscribe(result => {
        const queryId = result.uid;
        this.auth.getQuery().subscribe(result2 => {
          result2.forEach(query => {
            if (query.uid === queryId) {
              this.viewQuery(query);
            }
          });
          this.snackBar.open('Search query ' + ((this.update) ? 'updated.' : 'saved.'), 'OK', { duration: 5000 });
          this.update = false;
          this.router.navigate([], {
            queryParams: {
              update: Math.random()
            }, queryParamsHandling: 'merge'
          });
        });
      });
    }
  }

}
