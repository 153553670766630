<div class="scanner-main">

    <div class="scanner-lhs" [class.mobile]="routing.isMobile()">

        <div class="scanner-lhs-header">
            {{auth.getScoreName().name + ' Ranking'}}
        </div>

        <div class="scanner-lhs-content">
            <app-scanner-items></app-scanner-items>
        </div>

    </div>

    <div class="scanner-rhs" *ngIf="!routing.isMobile()">
        <div class="scanner-insights">
            <app-scanner-insights></app-scanner-insights>
        </div>
        <div class="scanner-news">
            <app-news-newsfeed></app-news-newsfeed>
        </div>
    </div>

</div>
