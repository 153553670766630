import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { YukkApi } from '../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../service/routing.service';
import { forkJoin, from, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-trending',
  templateUrl: './news-trending.component.html',
  styleUrls: ['./news-trending.component.scss']
})
export class NewsTrendingComponent implements OnInit {

  params: any;

  searchTerm: any;

  indices = ['all', 'DAX', 'MSCI World', 'Russell 1000', 'Russell 2000', 'Russell 3000', 'SP 500', 'STOXX 600'];
  selectedIndex: string;
  industries = [
    { value: 'all', label: 'all'},
    { value: 'basic_materials', label: 'Basic Materials'},
    { value: 'consumer_discretionary', label: 'Consumer Discretionary'},
    { value: 'consumer_goods', label: 'Consumer Goods'},
    { value: 'consumer_services', label: 'Consumer Services'},
    { value: 'consumer_staples', label: 'Consumer Staples'},
    { value: 'energy', label: 'Energy'},
    { value: 'financials', label: 'Financials'},
    { value: 'health_care', label: 'Health Care'},
    { value: 'industrials', label: 'Industrials'},
    { value: 'oil_gas', label: 'Oil & Gas'},
    { value: 'real_estate', label: 'Real Estate'},
    { value: 'technology', label: 'Technology'},
    { value: 'telecommunications', label: 'Telecommunications'},
    { value: 'utilities', label: 'Utilities'}
  ];
  selectedIndustry: string;
  trends = ['all', 'Bear', 'Bull'];
  selectedTrend: string;
  // scores = ['A', 'B', 'C', 'D'];
  // selectedScores: any;
  time_horizon = [
    { value: '1D', label: 'day'},
    { value: '1W', label: 'week'},
    { value: '1M', label: 'month'},
    { value: '3M', label: 'quarter'}
  ];
  selectedTimeHorizon: string;
  countries = [
    { value: 'all', label: 'all'},
    { value: 'ar', label: 'Argentina'},
    { value: 'at', label: 'Austria'},
    { value: 'au', label: 'Australia'},
    { value: 'bd', label: 'Bangladesh'},
    { value: 'be', label: 'Belgium'},
    { value: 'bm', label: 'Bermuda'},
    { value: 'br', label: 'Brazil'},
    { value: 'ca', label: 'Canada'},
    { value: 'ch', label: 'Switzerland'},
    { value: 'cl', label: 'Chile'},
    { value: 'cn', label: 'China'},
    { value: 'co', label: 'Colombia'},
    { value: 'cy', label: 'Cyprus'},
    { value: 'de', label: 'Germany'},
    { value: 'dk', label: 'Denmark'},
    { value: 'es', label: 'Spain'},
    { value: 'fi', label: 'Finland'},
    { value: 'fr', label: 'France'},
    { value: 'gb', label: 'United Kingdom'},
    { value: 'gg', label: 'Guernsey'},
    { value: 'gr', label: 'Greece'},
    { value: 'hk', label: 'Hong Kong'},
    { value: 'id', label: 'Indonesia'},
    { value: 'ie', label: 'Ireland'},
    { value: 'il', label: 'Israel'},
    { value: 'im', label: 'Isle of Man'},
    { value: 'in', label: 'India'},
    { value: 'it', label: 'Italy'},
    { value: 'je', label: 'Jersey'},
    { value: 'jp', label: 'Japan'},
    { value: 'kr', label: 'South Korea'},
    { value: 'ky', label: 'Cayman Islands'},
    { value: 'lu', label: 'Luxembourg'},
    { value: 'mx', label: 'Mexico'},
    { value: 'nl', label: 'Netherlands'},
    { value: 'no', label: 'Norway'},
    { value: 'nz', label: 'New Zealand'},
    { value: 'pk', label: 'Pakistan'},
    { value: 'pl', label: 'Poland'},
    { value: 'pt', label: 'Portugal'},
    { value: 'ru', label: 'Russia'},
    { value: 'sa', label: 'Saudi Arabia'},
    { value: 'se', label: 'Sweden'},
    { value: 'sg', label: 'Singapore'},
    { value: 'th', label: 'Thailand'},
    { value: 'tr', label: 'Turkey'},
    { value: 'tw', label: 'Taiwan'},
    { value: 'us', label: 'United States'},
    { value: 'vg', label: 'British Virgin Islands'},
    { value: 'vn', label: 'Vietnam'},
    { value: 'za', label: 'South Africa'}
  ];
  selectedCountry: string;

  entitiesList = [];

  nodata: boolean;
  loading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkaApi: YukkApi,
    public routing: RoutingService,
  ) {

    this.route.queryParams.subscribe(qparam => {
      this.params = qparam;
    });

    this.selectedIndex = 'all';
    this.selectedIndustry = 'all';
    this.selectedTrend = 'all';
    // this.selectedScores = ['A', 'B', 'C', 'D'];
    this.selectedTimeHorizon = '1W';
    this.selectedCountry = 'all';

    this.onSelectionChange();

  }

  ngOnInit() {
  }

  onSelectionChange(type = null) {

    this.nodata = false;
    this.loading = true;

    if ((type === 'country') && this.selectedCountry && (this.selectedCountry !== 'all')) {
      this.selectedIndex = 'all';
    } else if ((type === 'index') && this.selectedIndex && (this.selectedIndex !== 'all')) {
      this.selectedCountry = 'all';
    }

    const myParams = {
      amount: 50,
      stock_index: this.selectedIndex,
      industry: this.selectedIndustry,
      time_horizon: this.selectedTimeHorizon,
      country: this.selectedCountry
    };

    forkJoin({
      bearTrend: this.yukkaApi.bbtrending(myParams, 'bearish_stocks'),
      bullTrend: this.yukkaApi.bbtrending(myParams, 'bullish_stocks'),
    }).subscribe(results => {

      let bearEntities = results.bearTrend.bearish_stocks.map(el => {
        const trend_type = (el.bull_bear_score > 0) ? 'bull' : 'bear';
        const trend_value = Math.round(Math.abs(el.bull_bear_score) * 100);
        const volatility_value = Math.round(el.squid_score * 100);
        const volatility_type = (volatility_value && (volatility_value > 50)) ? 'dolphin' : 'whale';
        return {
          name: el.name,
          compound_key: el.yukka_id,
          type: el.yukka_id.split(':')[0],
          alpha_id: el.yukka_id.split(':')[1],
          isin: el.isin,
          logo: (el.entity_info && el.entity_info.media && el.entity_info.media.uni) ? el.entity_info.media.uni : null,
          trend_value: trend_value,
          trend_type: trend_type,
          volatility_value: volatility_value,
          volatility_type: volatility_type,
          country_name: (el.country_name) ? el.country_name : '',
          country_id: (el.country_id) ? el.country_id.toUpperCase() : '',
          price: el.price,
          price_delta: Math.round(el.price_delta * 100)
        };
      });

      let bullEntities = results.bullTrend.bullish_stocks.map(el => {
        const trend_type = (el.bull_bear_score > 0) ? 'bull' : 'bear';
        const trend_value = Math.round(Math.abs(el.bull_bear_score) * 100);
        const volatility_value = Math.round(el.squid_score * 100);
        const volatility_type = (volatility_value && (volatility_value > 50)) ? 'dolphin' : 'whale';
        return {
          name: el.name,
          compound_key: el.yukka_id,
          type: el.yukka_id.split(':')[0],
          alpha_id: el.yukka_id.split(':')[1],
          isin: el.isin,
          logo: (el.entity_info && el.entity_info.media && el.entity_info.media.uni) ? el.entity_info.media.uni : null,
          trend_value: trend_value,
          trend_type: trend_type,
          volatility_value: volatility_value,
          volatility_type: volatility_type,
          country_name: (el.country_name) ? el.country_name : '',
          country_id: (el.country_id) ? el.country_id.toUpperCase() : '',
          price: el.price,
          price_delta: Math.round(el.price_delta * 100)
        };
      });

      bearEntities = bearEntities.filter(el => {
        return el.trend_type === 'bear';
      });
      bullEntities = bullEntities.filter(el => {
        return el.trend_type === 'bull';
      });

      if (this.selectedTrend === 'Bull') {
        this.entitiesList = [...bullEntities];
      } else if (this.selectedTrend === 'Bear') {
        this.entitiesList = [...bearEntities];
      } else {
        this.entitiesList = [...bearEntities, ...bullEntities];
      }

      // const queriesPrice = [];
      // let itemsPriceCount = 0;
      // const dataPrice = new Array(this.entitiesList.length);
      //
      // this.entitiesList.forEach(item => {
      //   const params = {};
      //   if (item.type && item.alpha_id) {
      //     params['time'] = '11';
      //     params['type'] = item.type;
      //     params['id'] = item.alpha_id;
      //     queriesPrice.push(params);
      //   } else {
      //     queriesPrice.push(null);
      //   }
      // });
      // from(queriesPrice)
      //   .pipe(
      //       mergeMap((req, id) => ((req) ? this.yukkaApi.scoresTimeSeries('price', req, '') : of({})).pipe(map((reqObj, reqId) => ({reqId: id, reqObj}))))
      //   ).subscribe((queryItem) => {
      //     const price = (queryItem['reqObj']['prices'] && (queryItem['reqObj']['prices'].length > 0)) ? queryItem['reqObj']['prices'][queryItem['reqObj']['prices'].length - 1]['price'] : null;
      //     const priceChange = (queryItem['reqObj']['prices'] && (queryItem['reqObj']['prices'].length > 5)) ? (queryItem['reqObj']['prices'][queryItem['reqObj']['prices'].length - 1]['price'] - queryItem['reqObj']['prices'][queryItem['reqObj']['prices'].length - 6]['price']) : null;
      //     const price_delta = Math.round(priceChange * 100.0 / price);
      //     this.entitiesList[queryItem['reqId']]['price'] = price;
      //     this.entitiesList[queryItem['reqId']]['price_delta'] = price_delta;
      //     dataPrice[queryItem['reqId']] = {price: price, price_delta: price_delta};
      //     itemsPriceCount++;
      //     if (itemsPriceCount === this.entitiesList.length) {
      //       this.entitiesList = dataPrice.map((el, index) => {
      //         return Object.assign({}, this.entitiesList[index], {
      //           price: el.price,
      //           price_delta: el.price_delta
      //         });
      //       });
      //     }
      //     this.loading = false;
      //   }, error => {
      //     // this.nodata = true;
      //     this.loading = false;
      //   });

      const queriesScore = [];
      let itemsScoreCount = 0;
      const dataScore = new Array(this.entitiesList.length);

      this.entitiesList.forEach(item => {
        const params = {};
        if (item.type && item.alpha_id) {
          params['time'] = '1';
          params['type'] = item.type;
          params['id'] = item.alpha_id;
          params['score_type'] = 'esg';
          params['requestScore'] = 'esg';
          queriesScore.push(params);
        } else {
          queriesScore.push(null);
        }
      });
      from(queriesScore)
          .pipe(
              mergeMap((req, id) => ((req) ? this.yukkaApi.scoresTimeSeries('score', req, '') : of({})).pipe(map((reqObj, reqId) => ({reqId: id, reqObj}))))
          ).subscribe((queryItem) => {
        const score = (queryItem['reqObj']['score_ts'] && (queryItem['reqObj']['score_ts'].length > 0)) ? queryItem['reqObj']['score_ts'][queryItem['reqObj']['score_ts'].length - 1]['score']?.toFixed(1) : null;
        this.entitiesList[queryItem['reqId']]['score'] = score;
        // let scoreCategory;
        // if (score && (score >= 0.0) && (score < 2.5)) {
        //   scoreCategory = 'D';
        // } else if (score && (score >= 2.5) && (score < 5.0)) {
        //   scoreCategory = 'C';
        // } else if (score && (score >= 5.0) && (score < 7.5)) {
        //   scoreCategory = 'B';
        // } else if (score && (score >= 7.5)) {
        //   scoreCategory = 'A';
        // }
        // this.entitiesList[queryItem['reqId']]['score_category'] = scoreCategory;
        dataScore[queryItem['reqId']] = {score: score/*, scoreCategory: scoreCategory*/};
        itemsScoreCount++;
        if (itemsScoreCount === this.entitiesList.length) {
          this.entitiesList = dataScore.map((el, index) => {
            return Object.assign({}, this.entitiesList[index], {
              score: el.score ? el.score : null/*,
              score_category: el.scoreCategory*/
            });
          });
        }
        this.loading = false;

        let time = '7';
        if (this.selectedTimeHorizon) {
          if (this.selectedTimeHorizon === '1D') {
            time = '1';
          } else if (this.selectedTimeHorizon === '1W') {
            time = '7';
          } else if (this.selectedTimeHorizon === '1M') {
            time = '30';
          } else if (this.selectedTimeHorizon === '3M') {
            time = '90';
          }
        }
        this.router.navigate([], {
          queryParams: {
            time: time,
            tag: null,
            changeTab: null
          },
          queryParamsHandling: 'merge',
          replaceUrl: false
        });

      }, error => {
        // this.nodata = true;
        this.loading = false;
      });

    }, error => {
      this.nodata = true;
      this.loading = false;
    });

  }

  filteredEntitiesList() {
    const filteredEntities = this.entitiesList;
    // const filteredEntities = this.entitiesList.filter(entity => {
    //   if (!(this.selectedScores.includes('A') && this.selectedScores.includes('B') && this.selectedScores.includes('C') && this.selectedScores.includes('D'))) {
    //     return this.selectedScores.includes(entity.score_category);
    //   } else {
    //     return true;
    //   }
    // });
    return filteredEntities;
  }

  // getScoreInfo(score) {
  //   if (score === 'D') {
  //     return '0-2.5';
  //   } else if (score === 'C') {
  //     return '2.5-5';
  //   } else if (score === 'B') {
  //     return '5-7.5';
  //   } else if (score === 'A') {
  //     return '7.5-10';
  //   }
  // }

}
