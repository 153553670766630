<div class="scores-main">
    <div class="scores-list">
        <div class="scores-list-header">
            <div class="scores-list-title">ScoreAI</div>
            <div class="scores-list-info">
                Use Yukka’s Score AI tool to build specific signals tailored to your needs or make use of our pre-built signals.
            </div>
        </div>
        <div class="scores-list-container">
            <div class="list-container-contents">
                <div class="yukka-scores" *ngIf="auth.scorelabSettings.sentimentAccess || auth.scorelabSettings.generalRiskAccess || auth.scorelabSettings.creditRiskAccess || auth.scorelabSettings.esgRiskAccess || auth.scorelabSettings.immediateRiskAccess || auth.scorelabSettings.esgAccess || auth.scorelabSettings.bbdAccess || auth.scorelabSettings.bbwAccess || auth.scorelabSettings.bbmAccess || auth.scorelabSettings.bbqAccess">
                    <div class="title">Yukka Scores</div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'sentiment'" *ngIf="auth.scorelabSettings.sentimentAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'sentiment'" (click)="setAsDefault('sentiment')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'sentiment'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'sentiment'" (click)="selectScore('sentiment')">Sentiment</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'general_risk'" *ngIf="auth.scorelabSettings.generalRiskAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'general_risk'" (click)="setAsDefault('general_risk')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'general_risk'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'general_risk'" (click)="selectScore('general_risk')">General Risk</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'credit_risk'" *ngIf="auth.scorelabSettings.creditRiskAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'credit_risk'" (click)="setAsDefault('credit_risk')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'credit_risk'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'credit_risk'" (click)="selectScore('credit_risk')">Credit Risk</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'esg_risk'" *ngIf="auth.scorelabSettings.esgRiskAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'esg_risk'" (click)="setAsDefault('esg_risk')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'esg_risk'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'esg_risk'" (click)="selectScore('esg_risk')">ESG Risk</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'immediate_risk'" *ngIf="auth.scorelabSettings.immediateRiskAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'immediate_risk'" (click)="setAsDefault('immediate_risk')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'immediate_risk'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'immediate_risk'" (click)="selectScore('immediate_risk')">Immediate Risk</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'esg'" *ngIf="auth.scorelabSettings.esgAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'esg'" (click)="setAsDefault('esg')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'esg'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'esg'" (click)="selectScore('esg')">ESG</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'eb_sim'" *ngIf="auth.scorelabSettings.eb_simAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'eb_sim'" (click)="setAsDefault('eb_sim')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'eb_sim'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'eb_sim'" (click)="selectScore('eb_sim')">EB-SIM</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'bbd'" *ngIf="auth.scorelabSettings.bbdAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'bbd'" (click)="setAsDefault('bbd')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'bbd'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'bbd'" (click)="selectScore('bbd')">Bull-Bear Daily</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'bbw'" *ngIf="auth.scorelabSettings.bbwAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'bbw'" (click)="setAsDefault('bbw')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'bbw'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'bbw'" (click)="selectScore('bbw')">Bull-Bear Weekly</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'bbm'" *ngIf="auth.scorelabSettings.bbmAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'bbm'" (click)="setAsDefault('bbm')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'bbm'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'bbm'" (click)="selectScore('bbm')">Bull-Bear Monthly</div>
                    </div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === 'bbq'" *ngIf="auth.scorelabSettings.bbqAccess">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== 'bbq'" (click)="setAsDefault('bbq')" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === 'bbq'" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === 'bbq'" (click)="selectScore('bbq')">Bull-Bear Quarterly</div>
                    </div>
                </div>
                <div class="custom-scores" *ngIf="auth.scorelabSettings.customScoreAccess">
                    <div class="title">My Custom Scores</div>
                    <div class="option" [class.active]="auth.scorelabSettings.defaultScore === score.uid" *ngFor="let score of customScores">
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore !== score.uid" (click)="setAsDefault(score.uid)" matTooltip="Set as Tool’s Default Score">radio_button_unchecked</mat-icon>
                        <mat-icon *ngIf="auth.scorelabSettings.defaultScore === score.uid" matTooltip="Set as Tool’s Default Score">check_circle_outlined</mat-icon>
                        <div class="score" [class.current]="currentScore.uid === score.uid" (click)="selectScore(score.uid)">{{score.name}}</div>
                    </div>
                </div>
            </div>
            <div class="list-container-actions">
                <div class="option" (click)="createScore()" *ngIf="auth.scorelabSettings.customScoreAccess">
                    <mat-icon>add_circle_outline</mat-icon>
                    <div class="score" [class.current]="currentScoreType === 'new'">Create Score</div>
                </div>
            </div>
        </div>
    </div>
    <div class="scores-settings">
        <div class="scores-settings-header">
            <div class="scores-settings-title">
                <div class="title-contents">
                    <div class="title-dyn" *ngIf="(currentScore.uid === 'sentiment') || (currentScore.uid === 'general_risk') || (currentScore.uid === 'credit_risk') || (currentScore.uid === 'esg_risk') || (currentScore.uid === 'immediate_risk') || (currentScore.uid === 'esg') || (currentScore.uid === 'eb_sim') || (currentScore.uid === 'bbd') || (currentScore.uid === 'bbw') || (currentScore.uid === 'bbm') || (currentScore.uid === 'bbq')">{{currentScore.name}}</div>
                    <div class="title-dyn" *ngIf="!((currentScore.uid === 'sentiment') || (currentScore.uid === 'general_risk') || (currentScore.uid === 'credit_risk') || (currentScore.uid === 'esg_risk') || (currentScore.uid === 'immediate_risk') || (currentScore.uid === 'esg') || (currentScore.uid === 'eb_sim') || (currentScore.uid === 'bbd') || (currentScore.uid === 'bbw') || (currentScore.uid === 'bbm') || (currentScore.uid === 'bbq'))" [class.notitle]="notitle">
                        <input matInput placeholder="Create score's name *" required [(ngModel)]="currentScore.name">
                    </div>
                </div>
                <div class="title-actions">
                    <mat-icon matTooltip="Close" matTooltipPosition="below" (click)="iClose()">close</mat-icon>
                </div>
            </div>
        </div>
        <div class="scores-settings-container">
            <div class="settings-container-contents info" *ngIf="currentScoreType === 'yukka'">
                <ng-container *ngIf="currentScore.uid === 'sentiment'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>YUKKA Lab’s Sentiment scores detects, for each meaningful part of a sentence (a clause), whether this is positive, negative or neutral. It also detects the entity the sentiment is about (target). The different sentiments about each unique target are aggregated at the sentence and article levels, providing an overall view of the sentiment towards the particular entity.</p>
                    <p>The sentiment score gives a precise overall picture of all the positive and negative coverage surrounding an entity. The sentiment score is on a scale of 0 to 100 where 100 is completely positive sentiment and 0 is completely negative sentiment.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'general_risk'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Yukka Lab’s default General Risk Score assesses companies  on a range of events. The rating is on a scale of 0 to 10 where 10 is very risky and 0 is not risky.</p>
                    <p>It’s calculated using our complex algorithm that consider a multitude of events and other factors.</p>
                    <p>Enable our General Risk Score to assess the riskiness of companies within your portfolio.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'credit_risk'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Yukka Lab’s Credit Risk Score assesses companies based on their news coverage for financial events or sector trends indicating negative credit change. The rating is on a scale of 0 to 10 where 10 is very risky and 0 is not risky.</p>
                    <p>It’s calibrated using machine learning models by our team of data scientists.</p>
                    <p>Enable our Credit Risk Score to assess the riskiness of companies within your portfolio.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'esg_risk'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Yukka Lab’s ESG Risk Score assesses companies based on Environmental, Social and Governmental activities that can constitute a risk. The score is on a scale of 0 to 10. A company that is engaging in highly risky activities w.r.t to E, S & G factors would receive a score closer to 10 and visa versa while a rating closer to 0 would imply that there is no known ESG Risks in the news.</p>
                    <p>Our ESG Risk score considered various events within each category of E, S and G. We then assign different weights and importance and determine the impact of each events before running it through our in house algorithms to determine an overall ESG Risk score.</p>
                    <p>Enable our ESG Risk Score to assess how the companies on your radar are performing in terms of ESG.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'immediate_risk'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Yukka Lab’s Immediate Risk Score assesses companies on a range of events that are of immediate risk to a company. The rating is on a scale of 0 to 10 where 10 is very risky and 0 is not risky.</p>
                    <p>It’s calculated in real-time using our in house algorithm that consider a multitude of events that require immediate attention based on their risk to the health of company.</p>
                    <p>Enable our Immediate Risk Score to assess the riskiness of companies within your portfolio.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'esg'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Yukka Lab’s default ESG Score rates companies based on Environmental, Social and Governmental activities. The rating is on a scale of 0 to 10. A company that is contributing positively overall to E, S & G factors would receive a rating closer to 10 and visa versa.</p>
                    <p>Our ESG score considered various events within each category of E, S and G. We then assign different weights and importance and determine the impact of each events before running it through our in house algorithms to determine an overall ESG score.</p>
                    <p>Enable our ESG Score to assess how the companies on your radar are performing in terms of ESG.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'eb_sim'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'bbd'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Our Bull Bear score algorithm monitors a vast array of news events to asses probability future stock price moves. The resulting Bull Bear score ranges from 0 to 100%, with 0% indicating worst predicted performance and 100% best predicted performance, allowing users to anticipate downtrends as well as investment opportunities of listed companies.</p>
                    <p>The daily signal is suited for a daily swing trading strategy and focused around short-term volatility.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'bbw'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Our Bull Bear score algorithm monitors a vast array of news events to asses probability future stock price moves. The resulting Bull Bear score ranges from 0 to 100%, with 0% indicating worst predicted performance and 100% best predicted performance, allowing users to anticipate downtrends as well as investment opportunities of listed companies.</p>
                    <p>The weekly signal is suited for a weekly rebalancing strategies and focused around short-term movements.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'bbm'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Our Bull Bear score algorithm monitors a vast array of news events to asses probability future stock price moves. The resulting Bull Bear score ranges from 0 to 100%, with 0% indicating worst predicted performance and 100% best predicted performance, allowing users to anticipate downtrends as well as investment opportunities of listed companies.</p>
                    <p>The monthly signal is suited for a monthly rebalancing  strategies and focused around mid-term movements.</p>
                </ng-container>
                <ng-container *ngIf="currentScore.uid === 'bbq'">
                    <p>This is a proprietary score provided by YUKKA Lab and can not be edited.</p>
                    <p>Our Bull Bear score algorithm monitors a vast array of news events to asses probability future stock price moves. The resulting Bull Bear score ranges from 0 to 100%, with 0% indicating worst predicted performance and 100% best predicted performance, allowing users to anticipate downtrends as well as investment opportunities of listed companies.</p>
                    <p>The quarterly signal is suited for a quarterly rebalancing  strategies and focused around long-term movements.</p>
                </ng-container>
            </div>
            <div class="settings-container-contents" *ngIf="currentScoreType !== 'yukka'">

                <div class="table-header">
                    <div class="table-row">
                        <div class="table-item">
                            <div class="box-value">Event</div>
                        </div>
                        <div class="table-values">
                            <div class="box-value" matTooltip="Select the importance of the event where 5 is very important." matTooltipPosition="above">Importance</div>
                            <div class="box-value" matTooltip="Select the type of impact this event has on you. Choose between positive and negative." matTooltipPosition="above">Impact</div>
                        </div>
                        <div class="table-actions"></div>
                    </div>
                </div>

                <div class="table-contents" #eventsContainer>
                    <div class="table-row" *ngFor="let event of currentScore.event_weights; index as index;">
                        <div class="table-item">

                            <div class="box-value" [class.invalid]="event.event_id === undefined">

                                <form [formGroup]="eventsForms[index]">
                                    <mat-form-field floatLabel="never" appearance="outline">
                                        <mat-label>
                                            <mat-icon>search</mat-icon>
                                            Click to scroll or type... *
                                        </mat-label>
                                        <input  #inputScoreEvent type="text"
                                               matInput
                                               required
                                               formControlName="eventsFormGroup"
                                               [matAutocomplete]="eventsAutocomplete">
                                        <mat-autocomplete #eventsAutocomplete="matAutocomplete">
                                            <mat-optgroup *ngFor="let group of eventsGroupsFiltered$[index] | async | orderBy: 'label'" [label]="group.label">
                                                <mat-option *ngFor="let eventName of group.events | orderBy" [value]="eventName">
                                                    {{eventName}}
                                                </mat-option>
                                            </mat-optgroup>
                                        </mat-autocomplete>
                                    </mat-form-field>
                                </form>

                            </div>
                        </div>
                        <div class="table-values">
                            <div class="box-value weight">
                                <mat-select [(ngModel)]="currentScore.event_weights[index].weight">
                                    <mat-option *ngFor="let weight of weightOptions" [value]="weight">{{weight}}</mat-option>
                                </mat-select>
                            </div>
                            <div class="box-value polarity">
                                <mat-select [(ngModel)]="currentScore.event_weights[index].polarity">
                                    <mat-option *ngFor="let polarity of polarityOptions" [value]="polarity.value">{{polarity.label}}</mat-option>
                                </mat-select>
                            </div>
                        </div>
                        <div class="table-actions">
                            <mat-icon matTooltip="Remove Event" matTooltipPosition="above" (click)="removeEvent(index)">close</mat-icon>
                        </div>
                    </div>
                    <div class="table-row last">
                        <button mat-button (click)="addEvent()">
                            <mat-icon>add_circle_outline</mat-icon>
                            <span>ADD EVENT</span>
                        </button>
                    </div>
                </div>

            </div>
            <!--<div class="settings-container-actions" *ngIf="currentScoreType !== 'yukka'"></div>-->
        </div>
        <div class="scores-settings-footer">
            <button class="delete" mat-button *ngIf="this.currentScoreType === 'custom'" (click)="deleteScore()" [class.delete-warning]="isButtonActive" (mouseover)="isButtonActive = true" (mouseout)="isButtonActive = false">
                <mat-icon>delete</mat-icon>
                <span>DELETE</span>
            </button>
            <button class="save" mat-button *ngIf="this.currentScoreType !== 'yukka'" (click)="saveScore()">
                <mat-icon>save</mat-icon>
                <span>SAVE</span>
            </button>
        </div>
    </div>
</div>
