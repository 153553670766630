import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from '../../../service/routing.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/service/auth.service';

/**
 * This component is displayed in the right bottom of the cockpit
 * It display the words relative to the entity with different size
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-tagcloud',
  templateUrl: './news-tagcloud.component.html',
  styleUrls: ['./news-tagcloud.component.scss']
})
export class TagcloudComponent {

  tagactive: string;
  tagmax: number;
  tagmin: number;
  params: any;
  theme: string;
  previousValue: any;
  mytag: string;
  result: any;
  chips: any;
  removable = true;
  openpanel = false;
  loading: boolean;
  items: any;
  items$: Observable<any>;
  error: string;
  // titolo$: Observable<YukkApi>
  myOrder: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.theme = routing.theme;
      this.params = params;
      this.chips = [];
      if (params.tag) {
        this.tagactive = decodeURI(params.tag.split('|')[0]);
        this.chips.push(decodeURI(params.tag.split('|')[1]));
      } else {
        this.tagactive = undefined;
      }
      this.mytag = '';
      // this.error = 'nodata'

      if (params.sort) {
        this.myOrder = '-total';
        if (params.sort === 'senti') { this.myOrder = '-sentiment'; }
        if (params.sort === '-senti') { this.myOrder = 'sentiment'; }
        if (params.sort === 'abc') { this.myOrder = 'word'; }
        if (params.sort === '-abc') { this.myOrder = '-word'; }
        if (params.sort === 'count') { this.myOrder = '-total'; }
        if (params.sort === '-count') { this.myOrder = 'total'; }
      } else {
        this.myOrder = '-total';
      }

      if (routing.reFresh(params, this.previousValue, ['type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'filter', 'newsday', 'update'])) {
        this.error = undefined;
        this.loading = true;
        this.items = [];
        // if (routing.isMobile() || routing.isIframe()) {
        //   this.titolo$ = this.yukkApi.isentiment(this.params, false)
        // }
        this.yukkApi.tagcloud(this.params).subscribe(result => {
          this.loading = false;
          if (result.tagcloud.length) {
            this.tagmin = result.tagcloud.reduce((min, b) => Math.min(min, b.score), result.tagcloud[0].score);
            this.tagmax = result.tagcloud.reduce((max, b) => Math.max(max, b.score), result.tagcloud[0].score);
            this.items = result.tagcloud.filter(word => {
              if (this.params.filter === 'pos') { return word.sentiment >= .6; }
              if (this.params.filter === 'neu') { return word.sentiment > .4 && word.sentiment < .6; }
              if (this.params.filter === 'neg') { return word.sentiment <= .4; }
              return word;
            });
          } else {
            this.error = 'nodata';
          }
        }, error => {
          this.loading = false;
          this.error = 'noapi';
        });
      }
      this.previousValue = params;

    });
  }

  goTagcloud(item) {
    if (item.tag === this.tagactive) {
      this.router.navigate([], {
        queryParams: {
          tag: null,
          news: null,
          // newstype: 'Chronological'
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([], {
        queryParams: {
          tag: encodeURI(item.tag) + '|' + item.word,
          news: null,
          // newstype: 'Chronological'
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  tagSelect(event) {
    this.mytag = event.option.value.word;
    this.goTagcloud(event.option.value);
  }

  removeChip() {
    this.router.navigate([], {
      queryParams: { tag: null, newsday: null, note: null, news: null },
      queryParamsHandling: 'merge',
      replaceUrl: false
    });
  }

}
