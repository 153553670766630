import { Component, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from 'src/app/service/routing.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { forkJoin, from, of, concat, Subject } from 'rxjs';
import { concatMap, mergeMap, map, catchError, toArray, takeUntil } from 'rxjs/operators';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { ColorVolumePipe } from 'src/app/pipe/colorVolume.pipe';
import { ColorTrendingPipe } from 'src/app/pipe/colorTrending.pipe';
import { ColorTrendingReversedPipe } from 'src/app/pipe/colorTrendingReversed.pipe';
import { ColorRatioPipe } from 'src/app/pipe/colorRatio.pipe';
import { ColorScorePipe } from 'src/app/pipe/color-score.pipe';
import { ColorScore2Pipe } from 'src/app/pipe/color-score2.pipe';
import { PortfolioIndexComponent } from '../../main-news/news-portfolio/portfolio-index/portfolio-index.component';
import { DialogTableComponent } from '../../../micro-components/dialog-table/dialog-table.component';
import { UntypedFormControl } from '@angular/forms';
import { ConfigService } from 'src/app/service/config.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-scanner-items',
  templateUrl: './scanner-items.component.html',
  styleUrls: ['./scanner-items.component.scss']
})
export class ScannerItemsComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  public Math = Math;

  /**
   * query parameters url
   */
  params: any;

  /**
   * scanner items based on portfolio
   */
  items: any;
  itemsCountMin: any;
  itemsCountMax: any;
  itemsTrendMax: any;
  itemsTrendMin: any;
  itemsRatioMax: any;
  itemsRatioMin: any;
  itemsScoreMin: any;
  itemsScoreMax: any;
  itemsScoreDeltaMin: any;
  itemsScoreDeltaMax: any;
  itemsScoresArrayMin = {};
  itemsScoresArrayMax = {};
  itemsScoresArrayDeltaMin = {};
  itemsScoresArrayDeltaMax = {};
  itemsLoaded: boolean;

  /**
   * id of the entity under mouse cursor
   */
  currentEntity: any;

  /**
   * portfolio content in the news-table's format
   */
  itemsFiltered: any;

  /**
   * sort order
   */
  myOrder: string;

  /**
   * refresh by parameters
   */
  previousValue: any;

  /**
   * errors returned by backend
   */
  nodata: boolean;

  /**
   * loading backend response
   */
  loading: boolean;

  /**
   * columns to display
   */
  ifilter: any;

  /**
   * columns array
   */
  array_filter = [];

  /**
   * color theme
   */

  theme = 'dark';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    public routing: RoutingService,
    public auth: AuthService,
    private yukkApi: YukkApi,
    public config: ConfigService
  ) {

    this.route.queryParams.subscribe(params => {
      this.params = JSON.parse(JSON.stringify(params));

      if (params.theme) {
        this.theme = params.theme;
      } else if (this.config.appConfig.setup.useStorage) {
        this.theme = localStorage.getItem('theme');
      } else {
        this.theme = 'dark';
      }
      if (this.theme === 'yukka') {
        this.theme = 'dark';
      }

      if (params.sort) {
        if (params.sort === 'score') {
          this.myOrder = '-score';
        } else if (params.sort === '-score') {
          this.myOrder = 'score';
        } else if (params.sort === 'score_delta') {
          this.myOrder = '-score_delta';
        } else if (params.sort === '-score_delta') {
          this.myOrder = 'score_delta';
        } else if (params.sort === 'senti') {
          this.myOrder = '-sentiment';
        } else if (params.sort === '-senti') {
          this.myOrder = 'sentiment';
        } else if (params.sort === 'abc') {
          this.myOrder = 'entity.name';
        } else if (params.sort === '-abc') {
          this.myOrder = '-entity.name';
        } else if (params.sort === 'trend') {
          this.myOrder = '-sentiment_delta';
        } else if (params.sort === '-trend') {
          this.myOrder = 'sentiment_delta';
        } else if (params.sort === 'count') {
          this.myOrder = '-count';
        } else if (params.sort === '-count') {
          this.myOrder = 'count';
        } else if (params.sort === 'ratio') {
          this.myOrder = '-volume_ratio';
        } else if (params.sort === '-ratio') {
          this.myOrder = 'volume_ratio';
        } else {
          if (params.sort.charAt(0) === '-') {
            this.myOrder = params.sort.substring(1);
          } else {
            this.myOrder = '-' + params.sort;
          }
        }
      } else {
        if (this.auth.scorelabSettings.defaultScore !== 'sentiment') {
          this.myOrder = 'score';
          this.params.sort = 'score';
        } else {
          this.myOrder = 'sentiment';
          this.params.sort = '-senti';
        }
      }
      if (this.params.id && this.routing.reFresh(this.params, this.previousValue, ['update', 'updateScoresOnSave', 'updateScores'])) {
        this.array_filter = [];
        this.ifilter = new UntypedFormControl([]);
        if (this.routing.isMobile()) {
          this.array_filter = [this.auth.scorelabSettings.defaultScore];
          this.ifilter = new UntypedFormControl([this.auth.scorelabSettings.defaultScore]);
        } else {
          this.auth.getScoresInfo()['yukka'].forEach(score => {
            this.array_filter.push(score.id);
            this.array_filter.push(score.id + '_delta');
          });
          this.auth.getScoresInfo()['custom'].forEach(score => {
            this.array_filter.push(score.id);
            this.array_filter.push(score.id + '_delta');
          });
          this.array_filter.push('volume');
          this.array_filter.push('volume_ratio');
          this.auth.getUserSettings('scannerColumns').subscribe(result => {
            if (result.entry) {
              let resultObj = JSON.parse(result.entry);
              resultObj = resultObj.filter(item => {
                return this.array_filter.includes(item);
              });
              this.ifilter = new UntypedFormControl([...new Set([...[this.auth.scorelabSettings.defaultScore, this.auth.scorelabSettings.defaultScore + '_delta'], ...resultObj])]);
            } else {
              this.ifilter = new UntypedFormControl([this.auth.scorelabSettings.defaultScore, this.auth.scorelabSettings.defaultScore + '_delta', 'volume', 'volume_ratio']);
            }
          });
        }
      }
      if (this.params.id && this.routing.reFresh(this.params, this.previousValue, ['id', 'update', 'updateScoresOnSave', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks'])) {

        // abort unwanted HTTP requests
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe = new Subject();

        this.nodata = false;
        this.loading = true;
        this.items = null;
        this.itemsLoaded = false;
        const content2 = (this.auth.folio) ? this.auth.folio.content : [];
        this.yukkApi.portfolio(Object.assign({}, this.params, {getSanctions: true}), content2).subscribe(result => {
          const content = [];
          this.items = result.treemap.children;
          this.items.forEach(item => {
            content.push(item.entity.compound_key);
            item.entity.name = (item.entity.name !== 'PNE') ? item.entity.name : ('PNE: ' + item.entity.alpha_id.split('.')[1] + ' (' + item.entity.alpha_id.split('.')[0] + ')');
          });
          this.itemsFiltered = this.items.map(item => {
            return {
              id: item.entity.alpha_id,
              type: item.entity.type,
              name: item.entity.name,
              sentiment: item.sentiment,
              trend: item.sentiment_delta,
              count: item.count,
              ratio: item.volume_ratio
            };
          });
          this.itemsCountMax = Math.max(...this.items.map(item => {
            return item.count;
          }), 1);
          this.itemsCountMin = Math.min(...this.items.map(item => {
            return item.count;
          }), 0);
          this.itemsTrendMax = Math.max(...this.items.map(item => {
            return item.sentiment_delta;
          }));
          this.itemsTrendMin = Math.min(...this.items.map(item => {
            return item.sentiment_delta;
          }));
          this.itemsRatioMax = Math.max(...this.items.map(item => {
            return item.volume_ratio;
          }));
          this.itemsRatioMin = Math.min(...this.items.map(item => {
            return item.volume_ratio;
          }));
          const itemsLength = this.items.length;
          if (itemsLength > 0) {
            // tslint:disable-next-line:no-shadowed-variable
            const params = JSON.parse(JSON.stringify(this.params));
            if (!params.time) {
              params['time'] = '7';
            }
            const requestsList = [];

            if (!(this.auth.scorelabSettings.defaultScore === 'sentiment')) {
              const chunkSize = 100;
              for (let i = 0; i < content.length; i += chunkSize) {
                requestsList.push(this.yukkApi.scoresTimeSeries('score', Object.assign({}, params, {
                  scoreForPortfolio: true,
                  itemized: true,
                  custom_payload: content.slice(i, (i + chunkSize))
                }), 'chart').pipe(catchError(error => {
                  console.log(error);
                  return of(Array(((content.length - i) < chunkSize) ? (content.length - i) : chunkSize).fill(undefined));
                })));
              }
            } else {
              requestsList.push(of(Array(content.length).fill(undefined)));
            }

            // const observables = (this.auth.scorelabSettings.defaultScore === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
            concat(...requestsList).pipe(toArray()).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result3 => {
              // @ts-ignore
              const result2 = result3.flat();
              result2.forEach((el, index) => {
                this.items[index]['score'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].score : null;
                this.items[index]['score_delta'] = (el && el.score_ts && (el.score_ts[el.score_ts.length - 1].score !== null)) ? el.score_ts[el.score_ts.length - 1].score - el.score_ts[0].score : null;
                if (['bbd', 'bbw', 'bbm', 'bbq'].includes(auth.scorelabSettings.defaultScore)) {
                  this.items[index]['trend_type'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].trend_type : null;
                  this.items[index]['trend_value'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].trend_value : null;
                }
              });
              const definedItems = this.items.filter(item => {
                return item.score;
              });
              this.itemsScoreMax = Math.max(...definedItems.map(item => {
                return item.score;
              }), 1);
              this.itemsScoreMin = Math.min(...definedItems.map(item => {
                return item.score;
              }), 0);
              const definedItems2 = this.items.filter(item => {
                return item.score_delta;
              });
              this.itemsScoreDeltaMax = Math.max(...definedItems2.map(item => {
                return item.score_delta;
              }), 1);
              this.itemsScoreDeltaMin = Math.min(...definedItems2.map(item => {
                return item.score_delta;
              }), 0);
              this.itemsLoaded = true;
              this.loading = false;

              this.auth.getUserSettings('scannerColumns').subscribe(res => {
                if (res.entry) {
                  let resultObj = JSON.parse(res.entry);
                  resultObj = [...new Set(resultObj.map(el => {
                    return this.getScoreId(el).scoreId;
                  }))];
                  resultObj.forEach(el => {
                    this.getScore(el);
                  });
                  if (this.auth.scorelabSettings.defaultFlag && !resultObj.includes(this.auth.scorelabSettings.defaultFlag.score_type)) {
                    this.getScore(this.auth.scorelabSettings.defaultFlag.score_type);
                  }
                } else {
                  if (this.auth.scorelabSettings.defaultFlag) {
                    this.getScore(this.auth.scorelabSettings.defaultFlag.score_type);
                  }
                }
              });
              if (this.auth.scorelabSettings.defaultFlag && (this.auth.scorelabSettings.defaultFlag.score_type === 'volume')) {
                this.getCurrentCount();
              }
              if (['esg', 'eb_sim', 'general_risk', 'esg_risk', 'immediate_risk', 'credit_risk'].includes(this.auth.scorelabSettings.defaultScore)) {
                this.getScore(this.auth.scorelabSettings.defaultScore + '_industry_bench');
              }
            }, error => {
              // this.nodata = true;
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        }, error => {
          this.nodata = true;
          this.loading = false;
        });
      }
      if (this.params.id && this.routing.reFresh(this.params, this.previousValue, ['updateFlag'])) {
        if (this.auth.scorelabSettings.defaultFlag && this.items) {
          if (this.auth.scorelabSettings.defaultFlag.score_type === 'volume') {
            this.getCurrentCount();
          } else {
            // abort unwanted HTTP requests
            this.ngUnsubscribe.next();
            this.ngUnsubscribe.complete();
            this.getScore(this.auth.scorelabSettings.defaultFlag.score_type);
          }
        }
      }

      this.previousValue = params;
    });

  }

  ngOnDestroy() {
    // abort unwanted HTTP requests
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * get news count for current day
   */
  getCurrentCount() {
    const itemsLength = this.items.length;
    if (itemsLength > 0) {
      const content = (this.auth.folio) ? this.auth.folio.content : [];
      this.yukkApi.portfolio(Object.assign({}, this.params, {
        time: 1
      }), content).subscribe(result => {
        result.treemap.children.forEach((item, index) => {
          this.items[index]['currentCount'] = item.count;
        });
      });
    }
  }

  /**
   * get score and change in score for a particular score id
   */
  getScore(scoreId) {
    const itemsLength = this.items.length;
    const mainId = this.getScoreId(scoreId).scoreId;
    if ((itemsLength > 0) && !this.items[0].hasOwnProperty(scoreId) && !(['sentiment', 'sentiment_delta', 'volume', 'volume_ratio'].includes(scoreId)) && !(mainId === this.auth.scorelabSettings.defaultScore)) {
      const content = (this.auth.folio) ? this.auth.folio.content : [];
      // tslint:disable-next-line:no-shadowed-variable
      const params = JSON.parse(JSON.stringify(this.params));
      if (!params.time) {
        params['time'] = '7';
      }
      const requestsList = [];
      const chunkSize = 100;
      for (let i = 0; i < content.length; i += chunkSize) {
        requestsList.push(this.yukkApi.scoresTimeSeries('score', Object.assign({}, params, {
          scoreForPortfolio: true,
          requestScore: (mainId.includes('industry_bench')) ? this.auth.scorelabSettings.defaultScore : mainId,
          industry_bench: (mainId.includes('industry_bench')) ? true : false,
          itemized: true,
          custom_payload: content.slice(i, (i + chunkSize))
        }), 'chart').pipe(catchError(error => of(undefined))));
      }
      // const observables = (mainId === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
      concat(...requestsList).pipe(toArray()).pipe(takeUntil(this.ngUnsubscribe)).subscribe(result3 => {
        // @ts-ignore
        const result2 = result3.flat();
        result2.forEach((el, index) => {
          this.items[index][mainId] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].score : null;
          if (!(mainId.includes('industry_bench'))) {
            this.items[index][mainId + '_delta'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].score - el.score_ts[0].score : null;
          } else {
            if (el && el.score_ts) {
              let industry = 'Industry';
              if (this.items[index].entity?.description?.Industry) {
                industry = this.items[index].entity.description.Industry.name;
              }
              let description = '';
              if (el.score_ts[el.score_ts.length - 1].score === 0.0) {
                description = 'Laggard';
              } else if (el.score_ts[el.score_ts.length - 1].score === 1.0) {
                description = 'Below average';
              } else if (el.score_ts[el.score_ts.length - 1].score === 2.0) {
                description = 'Above average';
              } else if (el.score_ts[el.score_ts.length - 1].score === 3.0) {
                description = 'Leader';
              }
              this.items[index][mainId + '_info'] = description + ' in ' + industry;
            } else {
              this.items[index][mainId + '_info'] = '';
            }
          }
          if (['bbd', 'bbw', 'bbm', 'bbq'].includes(mainId)) {
            this.items[index][mainId + '_trend_type'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].trend_type : null;
            this.items[index][mainId + '_trend_value'] = (el && el.score_ts) ? el.score_ts[el.score_ts.length - 1].trend_value : null;
          }
        });
        if (!(mainId.includes('industry_bench'))) {
          const definedItems = this.items.filter(item => {
            return item[mainId];
          });
          this.itemsScoresArrayMax[mainId] = Math.max(...definedItems.map(item => {
            return item[mainId];
          }), 1);
          this.itemsScoresArrayMin[mainId] = Math.min(...definedItems.map(item => {
            return item[mainId];
          }), 0);
          const definedItems2 = this.items.filter(item => {
            return item[mainId + '_delta'];
          });
          this.itemsScoresArrayDeltaMax[mainId] = Math.max(...definedItems2.map(item => {
            return item[mainId + '_delta'];
          }), 1);
          this.itemsScoresArrayDeltaMin[mainId] = Math.min(...definedItems2.map(item => {
            return item[mainId + '_delta'];
          }), 0);
        }
      });
    }
  }

  /**
   * check if item should be flagged
   */
  isFlagged(item) {
    if (item && item.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(item.entity.type)) {
      let currentScore;
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'volume') {
        // currentScore = item.count;
        currentScore = item.currentCount;
      } else if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        currentScore = item.sentiment * 100;
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        if (this.auth.scorelabSettings.defaultFlag.score_type === this.auth.scorelabSettings.defaultScore) {
          currentScore = item.score;
        } else if (item.hasOwnProperty(this.auth.scorelabSettings.defaultFlag.score_type)) {
          currentScore = item[this.auth.scorelabSettings.defaultFlag.score_type];
        } else {
          return false;
        }
      } else {
        return false;
      }
      if ((currentScore !== null) && (currentScore !== undefined)) {
        if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'ABOVE_EQUAL') {
          if (currentScore >= this.auth.scorelabSettings.defaultFlag.threshold) {
            return currentScore;
          } else {
            return false;
          }
        } else if (this.auth.scorelabSettings.defaultFlag.threshold_setting === 'BELOW_EQUAL') {
          if (currentScore <= this.auth.scorelabSettings.defaultFlag.threshold) {
            return currentScore;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * check whether requests for assigning flags are still loading
   */
  loadingFlag(item) {
    if (item && item.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(item.entity.type)) {
      if (this.auth.scorelabSettings.defaultFlag.score_type === 'volume') {
        if (item.currentCount !== undefined) {
          return false;
        } else {
          return true;
        }
      } else if (this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') {
        if (item.sentiment !== undefined) {
          return false;
        } else {
          return true;
        }
      } else if (this.auth.scorelabSettings.defaultFlag.score_type) {
        if (this.auth.scorelabSettings.defaultFlag.score_type === this.auth.scorelabSettings.defaultScore) {
          if (item.score !== undefined) {
            return false;
          } else {
            return true;
          }
        } else if (item[this.auth.scorelabSettings.defaultFlag.score_type] !== undefined) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
    // if (item && item.entity && this.auth.scorelabSettings.defaultFlag && this.auth.scorelabSettings.defaultFlag.entity_type.includes(item.entity.type)) {
    //   return true;
    // } else {
    //   return false;
    // }

  }

  /**
   * get tooltip for the particular item's flag
   */
  getFlagTooltip(item) {
    const threshold = this.auth.scorelabSettings.defaultFlag.threshold;
    const scoreName = this.auth.getScoresInfo(this.auth.scorelabSettings.defaultFlag.score_type)['full'];
    let score;
    if ((this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') || (this.auth.scorelabSettings.defaultFlag.score_type === 'volume')) {
      score = this.isFlagged(item) + ' of the ' + scoreName;
    } else {
      score = Number.parseFloat(this.isFlagged(item)).toFixed(2);
    }
    let tooltip = '';
    if ((this.auth.scorelabSettings.defaultFlag.score_type === 'sentiment') || (this.auth.scorelabSettings.defaultFlag.score_type === 'volume')) {
      tooltip = 'Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
    } else {
      tooltip = 'One or more of the events associated with ' + scoreName + ' occurred in the past 100 days. Current value ' + score + ' crossed the set threshold of ' + threshold + '.';
    }
    return tooltip;
  }

  /**
   * get score's details based on id
   */
  getScoreId(id) {
    const scoreIdArray = id.split('_');
    const isDelta = scoreIdArray.includes('delta') ? true : false;
    const scoreId = scoreIdArray.filter(item => item !== 'delta').join('_');
    return {scoreId, isDelta};
  }

  /**
   * get color from color pipes based on score's type and value
   * @param item
   */
  getScoreColor(item) {
    // if (this.myOrder === 'entity.name' || this.myOrder === '-entity.name') {
    //   const filterPipe = new ColorPipe(this.routing);
    //   return filterPipe.transform(item.sentiment);
    // }
    if (this.myOrder === '-sentiment' || this.myOrder === 'sentiment') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.sentiment);
    } else if (this.myOrder === '-sentiment_delta' || this.myOrder === 'sentiment_delta') {
      const filterPipe = new ColorTrendingPipe(this.routing);
      return filterPipe.transform(item.sentiment_delta, this.itemsTrendMin, this.itemsTrendMax);
    } else if (this.myOrder === '-count' || this.myOrder === 'count') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.count / this.itemsCountMax);
    } else if (this.myOrder === '-volume_ratio' || this.myOrder === 'volume_ratio') {
      const filterPipe = new ColorTrendingPipe(this.routing);
      let value = item.volume_ratio ? (item.volume_ratio * 100.0 - 100.0) : undefined;
      if (((item.volume_ratio === null) || (item.volume_ratio === 0)) && (item.count && (item.count > 0))) {
        value = (this.itemsRatioMin * 100.0 - 100.0);
      }
      return filterPipe.transform(value, (this.itemsRatioMin * 100.0 - 100.0), (this.itemsRatioMax * 100.0 - 100.0));
    } else if (this.myOrder === 'score_delta' || this.myOrder === '-score_delta') {
      const filterPipe = (this.auth.getScoreType() === 'pos') ? new ColorTrendingPipe(this.routing) : new ColorTrendingReversedPipe(this.routing);
      return filterPipe.transform(item.score_delta, this.itemsScoreDeltaMin, this.itemsScoreDeltaMax);
    } else if (this.myOrder === 'score' || this.myOrder === '-score') {
      const filterPipe = (this.auth.getScoreType() === 'pos') ? new ColorScore2Pipe(this.routing) : new ColorScorePipe(this.routing);
      return filterPipe.transform(item.score);
    } else {
      let scoreId = '';
      if (this.myOrder.charAt(0) === '-') {
        scoreId = this.myOrder.substring(1);
      } else {
        scoreId = this.myOrder;
      }
      const scoreMain = this.getScoreId(scoreId);
      if (scoreMain.isDelta) {
        const filterPipe = (this.auth.getScoreType(scoreMain.scoreId) === 'pos') ? new ColorTrendingPipe(this.routing) : new ColorTrendingReversedPipe(this.routing);
        return filterPipe.transform(item[scoreMain.scoreId + '_delta'], this.itemsScoresArrayDeltaMin[scoreMain.scoreId], this.itemsScoresArrayDeltaMax[scoreMain.scoreId]);
      } else {
        const filterPipe = (this.auth.getScoreType(scoreMain.scoreId) === 'pos') ? new ColorScore2Pipe(this.routing) : new ColorScorePipe(this.routing);
        return filterPipe.transform(item[scoreMain.scoreId]);
      }
    }
  }

  /**
   * get name of the filter based on its id
   */
  getFilterName(filter) {
    if (filter === 'volume') {
      return 'Volume';
    } else if (filter === 'volume_ratio') {
      return 'Volume change';
    } else if (this.getScoreId(filter).isDelta) {
      return (this.auth.getScoresInfo(this.getScoreId(filter).scoreId)['name'] + ' change');
    } else {
      return (this.auth.getScoresInfo(this.getScoreId(filter).scoreId)['name']);
    }
  }

  /**
   * apply chosen sorting option
   */
  goSort(value) {
    if (this.params.sort === value) {
      this.router.navigate([], {
        queryParams: { sort: '-' + value },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate([], {
        queryParams: { sort: value },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    }
  }

  /**
   * add tag to the url on item click
   */
  addTag(item) {
    if (!this.routing.isMobile()) {
      const tagItem = 'm:' + item.entity.type + ':' + item.entity.alpha_id;
      const tag = (this.params.tag === tagItem) ? null : encodeURI(tagItem);
      this.router.navigate([], {
        queryParams: {
          tag: tag,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          newstype: null,
          eventsFromScanner: null
        },
        queryParamsHandling: 'merge',
        replaceUrl: false
      });
    } else {
      this.router.navigate(['/mobile/news/market/chart'], {
        queryParams: {
          type: item.entity.type,
          id: item.entity.alpha_id,
          tag: null,
          news: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          factuality: null,
          temporality: null,
          newstype: null,
          eventsFromScanner: null
        }, queryParamsHandling: 'merge'
      });
    }
  }

  /**
   * navigate to entity's page on click
   */
  navEntity(entity) {

    let path = '/cockpit/news/portfolio/chart';
    if (this.routing.isMobile()) {
      path = '/mobile/news/market/chart';
    }
    this.router.navigate([path], {
      queryParams: {
        type: entity.type,
        id: entity.alpha_id,
        portfolioId: this.auth.folio ? this.auth.folio.uid : null,
        tag: null,
        template: null,
        user: null,
        folio: null,
        folioupdate: null,
        period: null,
        news: null,
        eventid: null,
        eventype: null,
        newstype: null,
        eventsFromScanner: null
      },
      queryParamsHandling: 'merge',
    });

    // if (this.routing.isMobile()) {
    //   this.router.navigate(['/mobile/news/market/chart'], {
    //     queryParams: {
    //       type: entity.type,
    //       id: entity.alpha_id,
    //       tag: null,
    //       news: null,
    //       eventid: null,
    //       eventype: null,
    //       newstype: null
    //     }, queryParamsHandling: 'merge'
    //   });
    // } else {
    //   this.router.navigate(['/cockpit/news/market/chart'], {
    //     queryParams: {
    //       type: entity.type,
    //       id: entity.alpha_id,
    //       tag: null,
    //       news: null,
    //       eventid: null,
    //       eventype: null,
    //       newstype: null
    //     }, queryParamsHandling: 'merge'
    //   });
    // }
  }

  onMouseEnterName(id) {
    this.currentEntity = id;
  }

  onMouseLeaveName() {
    this.currentEntity = null;
  }

  onMouseEnterRow(item) {
    item.visible = true;
  }

  onMouseLeaveRow(item) {
    item.visible = false;
  }

  /**
   * add new item
   */
  newIndex() {
    this.dialog.open(PortfolioIndexComponent, {
      data: {
        // uid: this.params.folio,
        action: 'new'
      }
    });
  }

  /**
   * edit specific item
   */
  editIndex(entity) {
    this.dialog.open(PortfolioIndexComponent, {
      data: {
        // uid: this.params.folio,
        action: 'edit',
        entity: entity
      }
    });
  }

  /**
   * get columns to be displayed based on selected scores
   */
  getAvailableScores() {
    return this.ifilter.value.filter(item => {
      return (!(['sentiment', 'sentiment_delta', 'volume', 'volume_ratio'].includes(item)) && !(item === this.auth.scorelabSettings.defaultScore) && !(item === (this.auth.scorelabSettings.defaultScore + '_delta')));
    });
  }

  /**
   * changed columns to be displayed
   */
  iFilter(event) {
    if (event === false) {
      const scannerColumns = this.ifilter.value.filter(item => {
        return ((item !== this.auth.scorelabSettings.defaultScore) && (item !== (this.auth.scorelabSettings.defaultScore + '_delta')));
      });
      const scannerColumnsValue = JSON.stringify(scannerColumns);
      this.auth.setUserSettings('scannerColumns', scannerColumnsValue).subscribe();
      scannerColumns.forEach(el => {
        this.getScore(el);
      });
    }
  }

  /**
   * menage score filters selection, limit number of selected options to 6
   */
  isOptionDisabled(option) {
    return (((this.ifilter.value.length > 5) && !this.ifilter.value.find(el => el === option)) || (option === this.auth.scorelabSettings.defaultScore) || (option === (this.auth.scorelabSettings.defaultScore + '_delta')));
  }

  /**
   * check if column should be displayed
   */
  iTool(value) {
    return this.ifilter.value.includes(value);
  }

  /**
   * open popup dialog with sanctions table
   */
  openDialog(entity) {
    this.dialog.open(DialogTableComponent, {
      data: {
        // uid: this.params.folio,
        title: 'Sanctions on ' + entity.name,
        table: entity.sanctions.map(sanction => {
          return {
            authority: sanction.sanctioned_by.name,
            authorityId: sanction.sanctioned_by.alpha_id,
            authorityType: sanction.sanctioned_by.type,
            program: sanction.program,
            start: sanction.sanctioned_from,
            end: sanction.sanctioned_to
          };
        })
      },
      width: '60%'
    });
  }

}
