import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from '../../../service/routing.service';
import { ConfigService } from '../../../service/config.service';

/**
* list of entity related
*/
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-treemap',
  templateUrl: './news-treemap.component.html',
  styleUrls: ['./news-treemap.component.scss']
})
export class NewsTreemapComponent {

  /**
  * data of the entity
  */
  company: any[any];

  /**
  * loading
  */
  loading: boolean;

  /**
  * query parameters url
  */
  params: any;

  /**
  * entities relation
  */
  relations: any;

  /**
  * show hide subentities
  */
  showall: number;

  /**
  * order by
  */
  myOrder: string;

  /**
  * parameters to be reload
  */
  previousValue: any;

  /**
  * when there is no data
  */
  nodata: boolean;

  /**
  *
  */
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public config: ConfigService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.params = params;
      if (this.params.sort) {
        if (this.params.sort === 'senti') { this.myOrder = '-sentiment'; }
        if (this.params.sort === '-senti') { this.myOrder = 'sentiment'; }
        if (this.params.sort === 'abc') { this.myOrder = 'entity.name'; }
        if (this.params.sort === '-abc') { this.myOrder = '-entity.name'; }
        if (this.params.sort === 'trend') { this.myOrder = '-sentiment_delta'; }
        if (this.params.sort === '-trend') { this.myOrder = 'sentiment_delta'; }
      } else {
        this.myOrder = '-sentiment';
      }
      if (routing.reFresh(params, this.previousValue, ['theme', 'type', 'id', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'query', 'period'])) {
        this.showall = undefined;
        this.company = undefined;
        this.loading = true;
        let types = [];
        if (params.type === 'company') {
          types = ['country', 'industry', 'person', 'sector', 'subsector', 'supersector', 'stock_index'];
        } else {
          types = ['company'];
        }
        this.yukkApi.relations(params, types).subscribe(result => {
          this.company = result;
          if (params.type && params.type !== 'hierarchy' || params.query) {
            this.relations = Object.keys(this.company.relations).map(item => this.company.relations[item]);
            this.relations = this.relations.filter(i => i.children.length > 0);
            this.relations = this.relations.filter(i => i.name !== 'Continent');
            this.relations = this.relations.sort((a, b) => a.children.length - b.children.length);
            this.relations.map(item => {
              if (this.params.type && (['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) {
                item.sentiment = null;
              }
              if (item.sentiment === null) { item.sentiment = -1; }
              item.children.map(itom => {
                if (this.params.type && (['industry', 'supersector', 'sector', 'subsector'].includes(this.params.type))) {
                  itom.sentiment = null;
                }
                if (itom.sentiment === null) { itom.sentiment = -1; }
              });
            });
            this.nodata = !this.relations.length;
          }
          this.loading = false;
        });
      }
      this.previousValue = params;
    });
  }

  /**
  * trigger the redirect or the expantion
  */
  goWhat(item, index) {
    item.children.length > 1 ? this.showAll(index) : this.goView(item.children[0]);
  }

  /**
  * redirect to the entity
  */
  goView(item: any) {
    if ((item.sentiment !== -1 || (this.config.appConfig.environment === 'dev'))) {
      this.router.navigate([], {
        queryParams: {
          type: item.entity.type, id: item.entity.alpha_id,
          tag: null, newsday: null, query: null, news: null,
        }, queryParamsHandling: 'merge'
      });
    }
  }

  /**
  * expand the box
  */
  showAll(index) {
    this.showall === index ? this.showall = undefined : this.showall = index;
  }

}
